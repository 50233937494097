import { auth } from "./firebase";

export function isLoggedIn(){
    return !!auth.currentUser
}

export function getUid(){
    return auth.currentUser?.uid
}

export function getUserDisplayId(){
    return auth.currentUser?.email ? auth.currentUser?.email : auth.currentUser?.phoneNumber
}

export function logout(){
    auth.signOut()
}
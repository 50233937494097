import { doc, getDoc } from "firebase/firestore"
import { convertTimestampField, firestore } from "./firebase"


export function getConversionData() {
    return new Promise((resolve, reject) => {
        getDoc(doc(firestore, `app/conversion`)).then((documentSnapshot) => {
            if (documentSnapshot.exists())
                resolve(convertTimestampField(documentSnapshot.data(), 'lastUpdate'))
            else
                resolve(null)
        }).catch((err)=> {
            reject(err)
        })
    })
}


export const carColors = new Map([
    ["흰색", "Blanc"],         
    ["검은색", "Noir"],         
    ["회색", "Gris"],        
    ["은색", "Argenté"],     
    ["빨간색", "Rouge"],      
    ["파란색", "Bleu"],          
    ["초록색", "Vert"],         
    ["노란색", "Jaune"],          
    ["주황색", "Orange"],          
    ["갈색", "Marron"],            
    ["보라색", "Violet"],        
    ["분홍색", "Rose"],          
    ["금색", "Doré"],             
    ["청록색", "Turquoise"],      
    ["청색", "Bleu Foncé"],        
    ["카키색", "Kaki"],           
    ["베이지색", "Beige"],        
    ["연두색", "Vert Clair"],     
    ["남색", "Marine"],           
    ["아이보리", "Ivoire"],        
    ["버건디", "Bordeaux"],        
    ["황금색", "Doré"],           
    ["쥐색", "Gris foncé"],
    ["진주색", " Blanc nacré"],
    ["검정색", "Noir"],
    ["은회색", "Gris argenté"]
  ]);
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: null,
}

export const favorites = createSlice({
  name: 'favorites',
  initialState,
  reducers: {
    addFavorite: (state, action) => {
      if (state.value)
        state.value = [...state.value, action.payload]
      else state.value = [action.payload]
    },
    removeFavorite: (state, action) => {
      state.value = state.value.filter(item => item.Id !== action.payload);
    },
    setFavorites: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { addFavorite, setFavorites, removeFavorite } = favorites.actions

export default favorites.reducer
import React, { useEffect, useRef, useState } from 'react';
import './i18n';
import './App.css'
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CarDetailsPage from './pages/CarDetailsPage';
import CarResultList from './pages/CarResultList';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import DrawerMenu from './pages/HomePage/components/DrawerMenu';
import Search from '@mui/icons-material/Search';
import Favorite from '@mui/icons-material/FavoriteBorder';
import Login from './pages/Login';
import PhoneLogin from './pages/PhoneLogin';
import Favorites from './pages/Favorites';
import { isLoggedIn } from './services/auth';
import Faq from './pages/Faq';
import Contact from './pages/Contact';
import HowItWorks from './pages/HowItWorks';
import { useTranslation } from 'react-i18next';
import AboutUs from './pages/AboutUs';
import Installation from './pages/Installation';

function App() {
  const [showBar, setShowBar] = useState(true)
  const homeRef = useRef()
  const location = useLocation();
  const { i18n } = useTranslation();
  const [deferredPrompt, setDeferredPrompt] = useState(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
        e.preventDefault();
        setDeferredPrompt(e); // Save the event for later use
        // console.log("install prompt fired", e)
    };

    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

    return () => window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
}, []);


  useEffect(() => {
    // Set the direction based on the language
    document.documentElement.dir = i18n.language === 'ar' ? 'rtl' : 'ltr';
  }, [i18n.language]);

  return (
    <Box sx={{ flexGrow: 1 }}>
      {/* AppBar for the top navigation */}
      {(location.pathname === '/') && <AppBar position="sticky" sx={{ background: '#fff', zIndex: showBar ? 100 : -100 }}>
        <Toolbar>
          {/* Conditionally render the DrawerMenu only on the Home page */}
          <DrawerMenu installPrompt={deferredPrompt}/>
          <div style={{width: 40}}/>
          <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', height: 40 }}>
            <div style={{ flex: 1 }} />
            <img src={require('./images/logo_with_bgd.png')} style={{ height: 60, marginTop: -10, width: 'auto' }} />
            <div style={{ flex: 1 }} />
            <IconButton onClick={()=> homeRef.current.showFilter()}>
              <Search sx={{ color: '#000' }} />
            </IconButton>
            <IconButton onClick={()=> window.location = isLoggedIn() ? '/favorites' : '/login'}>
              <Favorite sx={{ color: '#000' }} />
            </IconButton>
            
          </Typography>
        </Toolbar>
      </AppBar>}

      {/* Define routes for different components */}
      <Routes>
        <Route path="/" element={<HomePage ref={homeRef} onShowModal={(show)=> { setShowBar(!show)}}/>} />
        <Route path="/car/:id" element={<CarDetailsPage />} />
        <Route path="/list" element={<CarResultList />} />
        <Route path="/login" element={<Login />} />
        <Route path="/login/phone" element={<PhoneLogin />} />
        <Route path="/favorites" element={<Favorites />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/tutorial" element={<HowItWorks />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/install" element={<Installation />} />
      </Routes>
    </Box>

  );
}

const MainApp = () => (
  <Router>
    <App />
  </Router>
);

export default MainApp;

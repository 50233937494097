const models = [
  { name: "EQ900", name_ko: "EQ900" },
  { name: "G70", name_ko: "G70" },
  { name: "G80", name_ko: "G80" },
  { name: "G90", name_ko: "G90" },
  { name: "GV60", name_ko: "GV60" },
  { name: "GV70", name_ko: "GV70" },
  { name: "GV80", name_ko: "GV80" }
];

const genesis = {
    icon: require('../../images/logos/genesis.png'),
    name: "Genesis",
    name_ko: "제네시스",
    models: models
}

export default genesis
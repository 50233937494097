import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

const ImageViewerModal = ({ imageSrc, isOpen, onClose }) => {

    useEffect(() => {
        if (!isOpen) return;
    
        // Handle back button
        const handleBackButton = (event) => {
          event.preventDefault();
          onClose(); // Call the onClose handler when back button is pressed
        };
    
        window.addEventListener("popstate", handleBackButton);
    
        // Push a new history state to track the modal
        window.history.pushState(null, null, window.location.href);
    
        return () => {
          // Cleanup: Remove the event listener and revert the history state
          window.removeEventListener("popstate", handleBackButton);
          window.history.back(); // Go back to the previous state when modal is closed
        };
      }, [isOpen, onClose]);
      
    if (!isOpen) return null;

    return (
        <Modal show={isOpen} style={{backgroundColor: 'rgba(0, 0,0, 0)'}}>
            <div style={styles.modal}>
                <button onClick={onClose} style={styles.closeButton}>
                    ×
                </button>
                <TransformWrapper
                    initialScale={1}
                    minScale={0.5}
                    maxScale={3}
                >
                    <TransformComponent >
                        <img src={imageSrc} alt="Zoomable" style={styles.image} />
                    </TransformComponent>
                </TransformWrapper>
            </div>
        
      </Modal >

    );
};

const styles = {
    overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
    },
    modal: {
        position: "relative",
        backgroundColor: "rgba(0, 0, 0, 0.8)",
        borderRadius: "8px",
        maxWidth: "100%",
        height: "100vh",
        overflow: "hidden",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "10px",
        background: "transparent",
        border: "none",
        color: "#fff",
        fontSize: "24px",
        cursor: "pointer",
    },
    image: {
        width: "100%",
        height: "auto",
    },
};

export default ImageViewerModal;


const models = [
    { name: "QM3", name_ko: "QM3" },
    { name: "QM5", name_ko: "QM5" },
    { name: "QM6", name_ko: "QM6" },
    { name: "SM3", name_ko: "SM3" },
    { name: "SM5", name_ko: "SM5" },
    { name: "SM6", name_ko: "SM6" },
    { name: "SM7", name_ko: "SM7" },
    { name: "XM3", name_ko: "XM3" },
    { name: "Master", name_ko: "마스터" },
    { name: "Zoe", name_ko: "조에" },
    { name: "Captur", name_ko: "캡처" },
    { name: "Clio", name_ko: "클리오" },
    { name: "Twizy", name_ko: "트위지" },
    { name: "Arkana", name_ko: "아르카나" }
  ];
  
const samsung = {
    icon: require('../../images/logos/samsung.png'),
    name: "Renault(Samsung)",
    name_ko: "르노코리아(삼성)",
    models: models
}

export default samsung
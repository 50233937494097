export function getRandomInt(max) {
    return Math.floor(Math.random() * max);
}

export function isAnHourAgo(time){
   return Math.abs(new Date().getTime() - time) > 3600000
}
export function sortByName(arr) {
    return arr.sort((a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
    });
}
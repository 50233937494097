import axios from 'axios'
import { collection, getDocs, limit, orderBy, query, where, getCountFromServer, getDoc, doc } from 'firebase/firestore'
import { firestore, functions } from './firebase'
import { httpsCallable } from "firebase/functions";
import { getOriginalPrice } from '../utils/CarUtils'
import { getRandomInt } from '../utils/generic'
import hyundai from '../data/cars/hyundai'
import samsung from '../data/cars/samsung'
import chevrolet_ko from '../data/cars/chevrolet_ko'
import toyota from '../data/cars/toyota'
import { fuelTypes } from '../data/data'
import kg_mobility from '../data/cars/kg_mobility'


export function getCarDetails(carId) {
    const url = `https://api.encar.com/v1/readside/vehicle/${carId}?include=ADVERTISEMENT,CATEGORY,CONDITION,CONTACT,OPTIONS,PHOTOS,SPEC`
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res.data)
        }).catch(reject)
    })
}

export function getCarss() {
    const url = `https://api.encar.com/search/car/list/general?count=true&q=(And.Hidden.N._.(C.CarType.Y._.Manufacturer.현대.)_.FuelType.%EB%94%94%EC%A0%A4._.Transmission.%EC%98%A4%ED%86%A0._.SellType.%EC%9D%BC%EB%B0%98.)&sr=%7CModifiedDate%7C0%7C400&inav=%7CMetadata%7CSort&page%7C${1}`
    //const url = `https://api.encar.com/search/car/list/mobile?count=true&sr=%7CModifiedDate%7C0%7C50`
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res.data?.SearchResults)
        }).catch(reject)
    })
}

export function getCarInspection(carId) {
    const url = `https://api.encar.com/v1/readside/inspection/vehicle/${carId}`
    return new Promise((resolve, reject) => {
        axios.get(url).then(res => {
            resolve(res.data)
        }).catch(reject)
    })
}

const carsRef = collection(firestore, 'cars')


export function getCar(id) {
    return new Promise((resolve, reject) => {
        getDoc(doc(firestore, `cars/${id}`)).then((documentSnapshot) => {
            if (documentSnapshot.exists())
                resolve({ ...documentSnapshot.data() })
            else
                resolve(null)
        }).catch((err) => {
            reject(err)
        })
    })
}

export function getRecommendedCars() {
    let q = query(carsRef)

    const recommendeds = [
        { manufacturer: { name_ko: hyundai.name_ko }, model: { name_ko: "투싼 ix" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: samsung.name_ko }, model: { name_ko: "QM3" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: samsung.name_ko }, model: { name_ko: "QM6" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: chevrolet_ko.name_ko }, model: { name_ko: "올란도" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: chevrolet_ko.name_ko }, model: { name_ko: "크루즈" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: chevrolet_ko.name_ko }, model: { name_ko: "크루즈5" }, fuel: fuelTypes[0] },
        { manufacturer: { name_ko: chevrolet_ko.name_ko }, model: { name_ko: "어메이징 뉴 크루즈" }, fuel: fuelTypes[0] }
    ]
    const random = getRandomInt(recommendeds.length)

    const filters = { manufacturer: recommendeds[random].manufacturer, model: recommendeds[random].model, minYear: 2011 }
    const sorting = { sortBy: "Price", sortDirection: "asc" }

    q= query(getFilteredCarsQuery(filters, sorting))
    return new Promise((resolve, reject) => {
        getDocs(query(q, limit(4))).then(data => {
            const cars = data.docs.map((doc) => ({ ...doc.data() }))
            resolve({ cars, filters, sorting })
        }).catch(reject)
    })
}

export function getRIMPopularCars() {
    let q = query(carsRef)
    const random = getRandomInt(3)
    const recommendeds = [
        { manufacturer: toyota, modelGroup: { name_ko: "RAV4", name: "RAV4" }, minYear: 2015 },
        { manufacturer: kg_mobility, model: { name_ko: "렉스턴 스포츠 칸" }, minYear: 2017 },
        { manufacturer: chevrolet_ko, model: { name_ko: "콜로라도" }, minYear: 2017 }

    ]
    const filters = recommendeds[random]
    const sorting = { sortBy: "Price", sortDirection: "asc" }

    q = query(getFilteredCarsQuery(filters, sorting))

    return new Promise((resolve, reject) => {
        getDocs(query(q, limit(4))).then(data => {
            const cars = data.docs.map((doc) => ({ ...doc.data() }))
            resolve({ cars, filters, sorting })
        }).catch(reject)
    })
}

export function getLatest() {
    let q = query(carsRef)
    const random = getRandomInt(3)
    const sortyByUploadDate = { sortBy: "UploadDate", sortDirection: "desc", label: "recent" }
    const sortByFormYear = { sortBy: "FormYear", sortDirection: "desc", label: "latest" }
    const sortByCheapest = { sortBy: "Price", sortDirection: "asc", label: "cheapest" }
    const sortings = [sortyByUploadDate, sortByFormYear, sortByCheapest]

    const sorting = sortings[random]
    q = query(getFilteredCarsQuery({}, sorting), limit(4))
    return new Promise((resolve, reject) => {
        getDocs(q).then(data => {
            const cars = data.docs.map((doc) => ({ ...doc.data() }))
            resolve({ cars, filters: {}, sorting: sorting })
        }).catch(reject)
    })
}

export function getFilteredCarsQuery(filters, sorting, conversion) {
    const {
        manufacturer,
        modelGroup,
        model,
        fuel,
        minYear,
        maxYear,
        distanceRange,
        priceRange
    } = filters

    var q = query(carsRef)

    const manufacturerField = manufacturer?.name_ko ?? ""
    q = query(q, where("Manufacturer", ">=", manufacturerField), where("Manufacturer", "<=", manufacturerField+ '\uf8ff'))

    const modelGroupField = modelGroup?.name_ko ?? ""
    q = query(q, where("ModelGroup", ">=", modelGroupField), where("ModelGroup", "<=", modelGroupField+ '\uf8ff'))

    const modelField = model?.name_ko ?? ""
    q = query(q, where("Model", ">=", modelField), where("Model", "<=", modelField+ '\uf8ff'))

    const fuelField = fuel?.name_ko ?? ""
    q = query(q, where("FuelType", ">=", fuelField),  where("FuelType", "<=",  fuelField+ '\uf8ff'))

    q = query(q, where("FormYear", ">=", minYear ? minYear : 1900))

    q = query(q, where("FormYear", "<=", maxYear ? maxYear : Number.MAX_SAFE_INTEGER))

    if (distanceRange) {
        q = query(q, where("Mileage", ">=", distanceRange[0]))
        q = query(q, where("Mileage", "<=", distanceRange[1]))
    } else {
        q = query(q, where("Mileage", ">=", 0))
        q = query(q, where("Mileage", "<=", Number.MAX_SAFE_INTEGER))
    }
    if (priceRange) {
        const minPrice = getOriginalPrice(priceRange[0], conversion)
        const maxPrice = getOriginalPrice(priceRange[1], conversion)
        q = query(q, where("Price", ">=", minPrice))
        q = query(q, where("Price", "<=", maxPrice))
    } else {
        q = query(q, where("Price", ">=", 0))
        q = query(q, where("Price", "<=", Number.MAX_SAFE_INTEGER))
    }
    q = query(q, orderBy(sorting?.sortBy ?? "Price", sorting?.sortDirection ?? "asc"))
    return q
}

export function getCount(q) {
    return new Promise((resolve, reject) => {
        getCountFromServer(q)
            .then((data) => {
                const count = data.data().count
                resolve({ count })
            }).catch((e) => {
                reject(e)
            })
    })
}

export function svcRequestUpdateCar(carId, salesStatus) {
    const requestUpdate = httpsCallable(functions, 'requestUpdateCar');
    return new Promise((resolve, reject) =>
        requestUpdate({Id: carId, SalesStatus: salesStatus}).then((response) => resolve(response.data)).catch(reject)
    )
}
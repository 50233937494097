import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import './styles.css';
import { cars, fuelTypes, getYears } from '../../data/data';
import RangeSlider from '../RangeSlider';
import CustomDropdown from '../CustomDropdown';
import { isDefaultDistanceRange, isDefaultPriceRange } from '../../utils/CarUtils';
import { t } from 'i18next';
import { sortByName } from '../../utils/generic';

export const minPrice = 50000
export const maxPrice = 5000000

const SearchModal = ({ filters = {}, show, handleClose, onConfirm }) => {
    const [showAdvanced, setShowAdvanced] = useState(true)
    const [manufacturer, setManufacturer] = useState(filters.manufacturer);
    const [modelGroup, setModelGroup] = useState(filters.modelGroup);
    const [model, setModel] = useState(filters.model);
    const [fuel, setFuel] = useState(filters.fuel);
    const [minYear, setMinYear] = useState(filters.minYear)
    const [maxYear, setMaxYear] = useState(filters.maxYear)
    const [distanceRange, setDistanceRange] = useState(filters.distanceRange ?? [10000, 500000])
    const [priceRange, setPriceRange] = useState(filters.priceRange ?? [minPrice, maxPrice])
    const [transmission, setTransmission] = useState()
    const [interiorColor, setInteriorColor] = useState()
    const [exteriorColor, setExteriorColor] = useState()

    function handleManufacturerSelect(_manufacturer) {
        if (_manufacturer != manufacturer) setModelGroup(null)
        setManufacturer(_manufacturer)
    }

    function handleConfirm() {
        onConfirm && onConfirm({
            manufacturer,
            modelGroup,
            model,
            fuel,
            minYear,
            maxYear,
            distanceRange: isDefaultDistanceRange(distanceRange) ? null : distanceRange,
            priceRange: isDefaultPriceRange(priceRange) ? null : priceRange
        })
    }

    function handleReset() {
        setManufacturer(null)
        setModelGroup(null)
        setFuel(null)
        setMinYear(1980)
        setMaxYear(new Date().getFullYear())
        setDistanceRange([10000, 500000])
        setPriceRange([minPrice, maxPrice])
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 18, flex: 1 }}>{t("filters")}</Modal.Title>
                <button className='reset-container' onClick={handleReset}>
                    <div>
                        {t("reset")} <i className={`far fa-trash-can`} style={{ color: 'black' }}></i>
                    </div>
                </button>
            </Modal.Header>
            <Modal.Body >
                <div style={{height: '70vh', overflow: 'scroll'}}>
                <Form >
                    <Form.Group className='mb-3'>
                        <Form.Label >{t("brand")}</Form.Label>
                        <CustomDropdown
                            title={t("choose_brand")}
                            options={cars}
                            selected={manufacturer?.name}
                            setSelected={handleManufacturerSelect}
                        />
                    </Form.Group>
                    {manufacturer && <Form.Group className='mb-3'>
                        <Form.Label>{t("model")}</Form.Label>
                        <CustomDropdown
                            title={t("select_model")}
                            options={sortByName(manufacturer?.models)}
                            selected={modelGroup?.name}
                            setSelected={setModelGroup}
                            selectable={manufacturer}
                        />
                    </Form.Group>}
                    {/* <a className="voir_plus px-1" onClick={() => setShowAdvanced(!showAdvanced)}>{t("advanced")}</a> */}
                    {/* {manufacturer && <div className='d-flex justify-content-between align-items-center px-2' onClick={() => setShowAdvanced(!showAdvanced)}>
                        <label>{t("advanced")}</label>
                        <i className={`fas ${showAdvanced ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '0.65em' }}></i>
                    </div>} */}

                    {showAdvanced && <div className='mt-3'>
                        <Form.Group className='mb-3'>
                            <Form.Label>{t("fuel")}</Form.Label>
                            <CustomDropdown
                                title={t("select_fuel")}
                                options={fuelTypes}
                                selected={fuel?.name}
                                setSelected={setFuel}
                                selectable={manufacturer}
                            />
                        </Form.Group>

                        <Form.Group className='mb-3'>
                            <Form.Label>{t("year")}</Form.Label>
                            <div className='d-flex'>
                                <CustomDropdown
                                    title={t("min")}
                                    options={getYears()}
                                    selected={minYear}
                                    setSelected={setMinYear}
                                    selectable={manufacturer}
                                />
                                <CustomDropdown
                                    title={t("max")}
                                    options={getYears()}
                                    selected={maxYear}
                                    setSelected={setMaxYear}
                                    selectable={manufacturer}
                                />
                            </div>
                        </Form.Group>

                        <RangeSlider
                            title={t("mileage")}
                            value={distanceRange}
                            onChange={setDistanceRange}
                            suffix={t("km")}
                            min={10000}
                            max={500000}
                            step={10000}
                        />
                        <RangeSlider
                            title={t("price")}
                            value={priceRange}
                            onChange={setPriceRange}
                            suffix={t('mru')}
                            min={minPrice}
                            max={maxPrice}
                            step={50000}
                        />
                    </div>}

                    {/* Add more dropdowns here in the same manner */}
                </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="confirm-btn"
                    variant={"success"}
                    onClick={handleConfirm}
                    style={{ borderRadius: 25 }}>
                    {t("confirm")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export default SearchModal;

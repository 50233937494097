import audi from "../data/cars/audi";
import benz from "../data/cars/benz";
import bmw from "../data/cars/bmw";
import chevrolet_ko from "../data/cars/chevrolet_ko";
import ford from "../data/cars/ford";
import hyundai from "../data/cars/hyundai";
import jeep from "../data/cars/jeep";
import kg_mobility from "../data/cars/kg_mobility";
import kia from "../data/cars/kia";
import land_rover from "../data/cars/land_rover";
import lexus from "../data/cars/lexus";
import mini from "../data/cars/mini";
import nissan from "../data/cars/nissan";
import porsche from "../data/cars/porsche";
import samsung from "../data/cars/samsung";
import toyota from "../data/cars/toyota";
import volkswagen from "../data/cars/volkswagen";
import { carColors } from "../data/colors";
import manufacturers from "../data/manufacturers";
import { t } from 'i18next';


export function translateBrand(word) {
    return manufacturers.get(word)
}
export function translateModel(brand, word) {
    var models = new Map()
    switch (brand) {
        case hyundai.name_ko:
            models = new Map([...new Map(hyundai.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case kia.name_ko:
            models = new Map([...new Map(kia.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case samsung.name_ko:
            models = new Map([...new Map(samsung.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case chevrolet_ko.name_ko:
            models = new Map([...new Map(chevrolet_ko.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case toyota.name_ko:
            models = new Map([...new Map(toyota.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case bmw.name_ko:
            models = new Map([...new Map(bmw.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case jeep.name_ko:
            models = new Map([...new Map(jeep.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case kg_mobility.name_ko:
            models = new Map([...new Map(kg_mobility.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case benz.name_ko:
            models = new Map([...new Map(benz.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case audi.name_ko:
            models = new Map([...new Map(audi.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case ford.name_ko:
            models = new Map([...new Map(ford.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case nissan.name_ko:
            models = new Map([...new Map(nissan.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case porsche.name_ko:
            models = new Map([...new Map(porsche.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case mini.name_ko:
            models = new Map([...new Map(mini.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case land_rover.name_ko:
            models = new Map([...new Map(land_rover.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case volkswagen.name_ko:
            models = new Map([...new Map(volkswagen.models.map(car => [car.name_ko, car.name])), ...models])
            break;
        case lexus.name_ko:
            models = new Map([...new Map(lexus.models.map(car => [car.name_ko, car.name])), ...models])
            break;
    }
    const subModels = new Map([
        ["뉴", "New "],
        ["더", "The"],
        ["올", "All"],
        ["신형", "Nouveau modèle"],
        ["그랜드", "Grand"],
        ["쿠페", "Coupé"],
        ["프리미어", "Premier"],
        ["하이브리드", "Hybride"],
        ["세대", "e Génération"],
        ["노바", "Nova"],
        ["플래티넘", "Platinium"],
        ["네오", "Neo"],
        ["어메이징", "Amazing"],
        ["크리에이티브", "Creative"],
        ["클래스", "Class"],
        ["슈퍼", "Super"],
        ["스포츠", "Sports"],
        ["투리스모", "Turismo"],
        ["컨버터블", "Convertible"],
        ["크로스오버", "CrossOver"],
        ["칸", "Khan"],
        ["베리", "Very"],
        ["아레나", "Arena"],
        ["리얼", "Real"],
        ["프라임", "Prime"],
        ["살룬", "Saloon"],
        ["넥스트", "Next"]
    ])
    models = new Map([...models, ...subModels])

    models.forEach((value, key) => {
        word = word.replace(key, value)
    })
    return word;

}

export function translateFuel(gradeName, fuel) {
    fuel = fuel.replace("디젤", t("diesel"))
    fuel = fuel.replace("가솔린", t("gasoline"))
    fuel = fuel.replace("전기", t('electric'))

    const wdIdx = gradeName.indexOf("WD")

    if (wdIdx > 0) 
        return fuel + " " + gradeName.substring(wdIdx - 1, wdIdx + 3).replace("4WD", "4x4")
    return fuel
}

export function translateTransmission(transmission) {
    transmission = transmission.replace("오토", t('auto'))
    transmission = transmission.replace("수동", t("manual"))
    transmission = transmission.replace("세미오토", t('semi_auto'))
    transmission = transmission.replace("기타", t('other'))

    return transmission
}

export function translateColors(color) {
    return carColors.has(color) ? t(color) : t('other')
}
export const koreanBrands = new Map([
    ["현대", "Hyundai"],
    ["제네시스", "Genesis"],
    ["기아", "Kia"],
    ["쉐보레(GM대우)", "Chevrolet"],
    ["르노코리아(삼성)", "Renault (Samsung)"],
    ["KG모빌리티(쌍용)", "KG Mobility"],

]);
export const internationalBrands = new Map([
        ["도요타", "Toyota"],
        ["BMW", "BMW"],
        ["벤츠", "Mercedes-Benz"],
        ["지프", "Jeep"],
        ["르노", "Renault"],
        ["닛산", "Nissan"],
        ["렉서스", "Lexus"],
        ["쉐보레", "Chevrolet"],
        ["랜드로버", "Land Rover"],
        ["미쯔비시", "Mitsubishi"],
        ["포드", "Ford"],
        ["폭스바겐", "Volkswagen"],
        ["아우디", "Audi"],
        ["볼보", "Volvo"],
        ["푸조", "Peugeot"],
        ["시트로엥/DS", "Citroën/DS"],
        ["피아트", "Fiat"],

        ["벤틀리", "Bentley"],
        ["부가티", "Bugatti"],
        ["페라리", "Ferrari"],
        ["험머", "Hummer"],
        ["람보르기니", "Lamborghini"],
        ["마세라티", "Maserati"],
        ["마이바흐", "Maybach"],
        ["맥라렌", "McLaren"],
        ["포르쉐", "Porsche"],
        ["롤스로이스", "Rolls-Royce"],
        ["링컨", "Lincoln"],
        ["테슬라", "Tesla"],


        ["GMC", "GMC"],
        ["다이하쯔", "Daihatsu"],
        ["닷지", "Dodge"],
        ["동풍소콘", "Dongfeng Sokon"],
        ["MG로버", "MG Rover"],
        ["로터스", "Lotus"],
        
        ["마쯔다", "Mazda"],
        ["머큐리", "Mercury"],
        ["미니", "Mini"],
        ["미쯔오까", "Mitsuoka"],
        ["북기은상", "BAIC Yinxiang"],
        ["뷰익", "Buick"],
        ["사브", "Saab"],
        ["사이언", "Scion"],
        ["새턴", "Saturn"],
        ["스마트", "Smart"],
        ["스바루", "Subaru"],
        ["스즈키", "Suzuki"],
        
        
        ["알파 로메오", "Alfa Romeo"],
        ["애스턴마틴", "Aston Martin"],
        ["어큐라", "Acura"],
        ["오펠", "Opel"],
        ["올즈모빌", "Oldsmobile"],
        ["이스즈", "Isuzu"],
        ["인피니티", "Infiniti"],
        ["재규어", "Jaguar"],
        
        ["캐딜락", "Cadillac"],
        ["코닉세그", "Koenigsegg"],
        ["크라이슬러", "Chrysler"],
        ["파가니", "Pagani"],
        ["포톤", "Foton"],
        ["폰티악", "Pontiac"],
        ["폴스타", "Polestar"],
        ["혼다", "Honda"]
])

const manufacturers = new Map([...koreanBrands, ...internationalBrands])

export default manufacturers
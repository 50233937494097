
const models = [
  { name: "Note", name_ko: "노트" },
  { name: "Rogue", name_ko: "로그" },
  { name: "Laurel", name_ko: "로렐" },
  { name: "Leaf", name_ko: "리프" },
  { name: "March", name_ko: "마치" },
  { name: "Maxima", name_ko: "맥시마" },
  { name: "Moco", name_ko: "모코" },
  { name: "Murano", name_ko: "무라노" },
  { name: "Versa", name_ko: "버사" },
  { name: "Versa", name_ko: "베르사" },
  { name: "Bluebird Sylphy", name_ko: "블루버드 실피" },
  { name: "Bluebird", name_ko: "블루버드" },
  { name: "Cedric", name_ko: "세드릭" },
  { name: "Serena", name_ko: "세레나" },
  { name: "Cefiro", name_ko: "세피로" },
  { name: "Sentra", name_ko: "센트라" },
  { name: "Skyline", name_ko: "스카이라인" },
  { name: "Cima", name_ko: "시마" },
  { name: "Silvia", name_ko: "실비아" },
  { name: "Armada", name_ko: "알마다" },
  { name: "Altima", name_ko: "알티마" },
  { name: "Xterra", name_ko: "엑스테라" },
  { name: "X-Trail", name_ko: "엑스트레일" },
  { name: "Elgrand", name_ko: "엘그란드" },
  { name: "Wingroad", name_ko: "윙로드" },
  { name: "Juke", name_ko: "쥬크" },
  { name: "Qashqai", name_ko: "캐시카이" },
  { name: "Quest", name_ko: "퀘스트" },
  { name: "Cube", name_ko: "큐브" },
  { name: "Titan", name_ko: "타이탄" },
  { name: "Teana", name_ko: "티아나" },
  { name: "Pao", name_ko: "파오" },
  { name: "Pathfinder", name_ko: "패스파인더" },
  { name: "Pulsar", name_ko: "펄사" },
  { name: "Fuga", name_ko: "푸가" },
  { name: "Prairie", name_ko: "프레리" },
  { name: "President", name_ko: "프레지던트" },
  { name: "Frontier", name_ko: "프론티어" },
  { name: "Figaro", name_ko: "휘가로" },
  { name: "180SX", name_ko: "180SX" },
  { name: "200SX", name_ko: "200SX" },
  { name: "240SX", name_ko: "240SX" },
  { name: "280ZX", name_ko: "280ZX" },
  { name: "300ZX", name_ko: "300ZX" },
  { name: "350Z", name_ko: "350Z" },
  { name: "370Z", name_ko: "370Z" },
  { name: "GT-R", name_ko: "GT-R" },
  { name: "NV", name_ko: "NV" },
  { name: "Lafesta", name_ko: "라페스타" }
];
  
  
const nissan = {
    icon: require('../../images/logos/nissan.png'),
    name: "Nissan",
    name_ko: "닛산",
    models: models
}

export default nissan

const models = [
  { name: "E-Series", name_ko: "E-Series" },
  { name: "F150", name_ko: "F150" },
  { name: "F250", name_ko: "F250" },
  { name: "F350", name_ko: "F350" },
  { name: "GT", name_ko: "GT" },
  { name: "S-MAX", name_ko: "S-MAX" },
  { name: "Ranger", name_ko: "레인저5" },
  { name: "Mustang", name_ko: "머스탱" },
  { name: "Mondeo", name_ko: "몬데오" },
  { name: "Bronco", name_ko: "브롱코" },
  { name: "Thunderbird", name_ko: "썬더버드" },
  { name: "Windstar", name_ko: "윈드스타" },
  { name: "Escape", name_ko: "이스케이프" },
  { name: "Econoline", name_ko: "이코노라인" },
  { name: "Explorer Sport Trac", name_ko: "익스플로러 스포츠트랙" },
  { name: "Explorer", name_ko: "익스플로러" },
  { name: "Expedition", name_ko: "익스페디션" },
  { name: "EcoSport", name_ko: "에코스포츠" },
  { name: "Contour", name_ko: "컨투어" },
  { name: "Kuga", name_ko: "쿠가" },
  { name: "Taurus", name_ko: "토러스" },
  { name: "Transit", name_ko: "트랜짓" },
  { name: "Five Hundred", name_ko: "파이브 헌드레드" },
  { name: "Focus", name_ko: "포커스" },
  { name: "Fusion", name_ko: "퓨전" },
  { name: "Probe", name_ko: "프로브" },
  { name: "Freestyle", name_ko: "프리스타일" },
  { name: "Fiesta", name_ko: "피에스타" },
  { name: "Flex", name_ko: "플렉스" }
];
  
  
const ford = {
    icon: require('../../images/logos/ford.png'),
    name: "Ford",
    name_ko: "포드",
    models: models
}

export default ford
import { initializeApp } from "firebase/app";
import { getFirestore, Timestamp } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup, signInWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { getFunctions } from "firebase/functions";


const firebaseConfig = {
  apiKey: "AIzaSyD9D6x7awhB2QKnuR5autg4dwV3Q-TCZ5c",
  authDomain: "decoree-ad84b.firebaseapp.com",
  projectId: "decoree-ad84b",
  storageBucket: "decoree-ad84b.appspot.com",
  messagingSenderId: "471055169888",
  appId: "1:471055169888:web:3d11f9af4efe89c35ec605",
  measurementId: "G-TPJBEYQM82"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const analytics = getAnalytics(app)
const firestore = getFirestore(app)
const functions = getFunctions(app, "europe-west2")

const googleProvider = new GoogleAuthProvider();

// Export auth and Google provider
export { firestore, auth, functions, googleProvider, PhoneAuthProvider, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup, signInWithCredential};

export function timestampToDate(timestamp) {
    return timestamp.toDate()
}

export function dateToTimestamp(date = new Date()) {
    return Timestamp.fromMillis(date.getTime())
}

export function convertTimestampField(data, field = "timestamp") {
  data[field] =  timestampToDate(data[field]).getTime()
  return data
}

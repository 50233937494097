import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Container, Row, Card } from 'react-bootstrap';
import './styles.css';
import { formatMileage, getCarPhoto, getPrice, isCarOnSale } from '../../utils/CarUtils';
import { translateBrand, translateModel } from '../../services/translator';
import Header from './components/Header';
import SearchBar from './components/SearchBar';
import SortModal from './components/SortModal';
import { getCount, getFilteredCarsQuery } from '../../services/car_data';
import useCars from '../../hooks/useCars';
import useConversion from '../../hooks/useConversion';
import { IconButton } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite';
import FavoriteOutline from '@mui/icons-material/FavoriteBorder';
import useFavorites from '../../hooks/useFavorites';
import { isLoggedIn } from '../../services/auth';
import { useDispatch } from 'react-redux';
import { addFavorite, removeFavorite } from '../../reducers/favorites';
import { addToFavorites, removeFromFavorites } from '../../services/userInfo';
import { t } from 'i18next';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';
import CarUnavailable from '../../components/CarUnavailable';


const CarResultList = () => {
  const conversion = useConversion()
  const favorites = useFavorites()
  const navigate = useNavigate()
  const { state, pathname } = useLocation()
  const filters = state?.filters ?? {}
  const sorting = state?.sorting ?? {}
  const [query, setQuery] = useState(getFilteredCarsQuery(filters, sorting, conversion))
  const [isLoading, setLoading] = useState(true)
  const [count, setCount] = useState(0)
  const [showSort, setShowSort] = useState(false)
  const [filteredCars, setFilteredCars] = useState([]);
  const dispatch = useDispatch()

  function handleSearch(data) {
    setLoading(true)
    setQuery(getFilteredCarsQuery(filters, sorting, conversion))
    navigate(pathname, { replace: true, state: { filters: data, sorting: sorting } })
    navigate(0)
  }

  function handleConfirmSort(sorting) {
    setLoading(true)
    setQuery(getFilteredCarsQuery(filters, sorting, conversion))
    setShowSort(false)
    navigate(pathname, { replace: true, state: { filters: filters, sorting: sorting } })
    navigate(0)
  }

  function handleCardPress(id) {
    window.open(`/car/${id}`, '_blank');
  }

  function handleFavorite(car) {

    if (!isLoggedIn()) {
      alert(t("sign-in-to-like"))
      return
    }
    const id = car.Id

    const hasFavorite = favorites && favorites.some(item => item.Id === id);
    if (hasFavorite) {
      dispatch(removeFavorite(id))
      removeFromFavorites(car)
    } else {
      dispatch(addFavorite(car))
      addToFavorites(car)
    }
  }

  useEffect(() => {
    if (conversion)
      getCount(getFilteredCarsQuery(filters, sorting, conversion)).then((data) => {
        setCount(data.count)
      })
  }, [conversion])

  useCars(getFilteredCarsQuery(filters, sorting, conversion), (data) => {
    setFilteredCars(data)
  }, (isLoading) => {
    setLoading(isLoading)
  }, (error) => {

  })


  return (
    <>
      <Header onSort={() => setShowSort(true)} resultCount={count} />
      <Container className="mt-0" style={{ paddingLeft: 0, paddingRight: 0 }}>

        <SearchBar className="mx-3 my-3" onSearch={handleSearch} />

        {(conversion && filteredCars.length > 0) && <Row className='g-2 px-3 pb-3'>
          {filteredCars.map((car, index) => (
            <div key={car.Id}>
              <Card className="mb-0 car-card-link w-100">
                <div className="image-container">
                  <Card.Img variant="top" src={getCarPhoto(car?.Photos[0]?.location)} alt={car.Model} onClick={() => handleCardPress(car.Id)} />
                  <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => handleFavorite(car)}>
                    {favorites && favorites.some(item => item.Id === car.Id) ? <Favorite sx={{ color: 'red' }} /> : <FavoriteOutline sx={{ color: '#fff' }} />}
                  </IconButton>
                  {!isCarOnSale(car.SalesStatus) && <CarUnavailable status={car.SalesStatus}/>}
                </div>
                <Card.Body onClick={() => handleCardPress(car.Id)}>
                  <Card.Text className="car-big-title">{translateBrand(car.Manufacturer)} {translateModel(car.Manufacturer, car.Model)}</Card.Text>
                  <Card.Text className="car-big-year">{t("year")}: {car.FormYear}/{formatMileage(car.Mileage)}</Card.Text>
                  <Card.Text className="car-big-price">{getPrice(car.Price, conversion)}</Card.Text>
                  {/* <Link to={`/car/${car.id}`} className="btn btn-primary">View Details</Link> */}
                </Card.Body>
              </Card>
            </div>
          ))}

        </Row>}
        {isLoading && <Row className='g-2 px-3 pb-3'>
          <ShimmerSimpleGallery imageHeight={338.5} col={1} row={4} />
        </Row>}
        {(!isLoading && filteredCars.length == 0) && <div className='no-result'>{t("no_result")}<br />{t("adjust_filters")}</div>}
        {showSort && <SortModal
          show={showSort}
          handleClose={() => setShowSort(false)}
          onConfirm={handleConfirmSort}
          defaultValue={sorting}
        />}

      </Container>
    </>
  );
};

export default CarResultList;

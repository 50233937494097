import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  value: sessionStorage.getItem('conversion') != null ? JSON.parse(sessionStorage.getItem('conversion')) : null,
}

export const conversion = createSlice({
  name: 'conversion',
  initialState,
  reducers: {
    setConversion: (state, action) => {
      state.value = {...state.value, ...action.payload}
      sessionStorage.setItem("conversion", JSON.stringify(action.payload))
    },
    resetConversion: (state, action) => {
      state.value = action.payload
    }
  },
})

export const { setConversion, resetConversion } = conversion.actions

export default conversion.reducer
const hyundai = {
    icon: require('../../images/logos/hyundai.png'),
    name: "Hyundai",
    name_ko: "현대",
    models: [
        { name_ko: "그랜저", name: "Grandeur" },
        { name_ko: "아반떼", name: "Elantra" },
        { name_ko: "쏘나타", name: "Sonata" },
        { name_ko: "싼타페", name: "Santa Fe" },
        { name_ko: "스타렉스", name: "Starex" },
        { name_ko: "투싼", name: "Tucson" },
        { name_ko: "팰리세이드", name: "Palisade" },
        { name_ko: "제네시스", name: "Genesis" },
        { name_ko: "코나", name: "Kona" },
        { name_ko: "스타리아", name: "Staria" },
        { name_ko: "에쿠스", name: "Equus" },
        { name_ko: "아이오닉5", name: "Ioniq 5" },
        { name_ko: "엑센트", name: "Accent" },
        { name_ko: "캐스퍼", name: "Caspian" },
        { name_ko: "벨로스터", name: "Veloster" },
        { name_ko: "i30", name: "i30" },
        { name_ko: "베뉴", name: "Venue" },
        { name_ko: "맥스크루즈", name: "Maxcruz" },
        { name_ko: "아이오닉", name: "Ioniq" },
        { name_ko: "i40", name: "i40" },
        { name_ko: "베라크루즈", name: "Veracruz" },
        { name_ko: "넥쏘", name: "Nexo" },
        { name_ko: "쏠라티", name: "Solati" },
        { name_ko: "아이오닉6", name: "Ioniq 6" },
        { name_ko: "아슬란", name: "Aslan" },
        { name_ko: "갤로퍼", name: "Galloper" },
        { name_ko: "테라칸", name: "Terracan" },
        { name_ko: "다이너스티", name: "Dynasty" },
        { name_ko: "베르나", name: "Verna" },
        { name_ko: "투스카니", name: "Tuscani" },
        { name_ko: "클릭", name: "Click" },
        { name_ko: "산타모", name: "Santamo" },
        { name_ko: "스텔라", name: "Stella" },
        { name_ko: "트라제 XG", name: "Trajet XG" },
        { name_ko: "마르샤", name: "Marsha" },
        { name_ko: "엑셀", name: "Excel" },
        { name_ko: "티뷰론", name: "Tiburon" },
        { name_ko: "포니", name: "Pony" },
        { name_ko: "ST1", name: "ST1" },
        { name_ko: "라비타", name: "Lavita" },
        { name_ko: "아토스", name: "Atos" },
        { name_ko: "엘란트라", name: "Elantra" },
        { name_ko: "그라나다", name: "Granada" },
        { name_ko: "그레이스", name: "Grace" },
        { name_ko: "블루온", name: "BlueOn" },
        { name_ko: "스쿠프", name: "Scoop" },
        { name_ko: "코티나", name: "Cortina" },
        { name_ko: "프레스토", name: "Presto" }
    ]
}

export default hyundai
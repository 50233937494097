const models = [
  { name: "CT200h", name_ko: "CT200h" },
  { name: "ES", name_ko: "ES" },
  { name: "GS", name_ko: "GS" },
  { name: "GX", name_ko: "GX" },
  { name: "IS", name_ko: "IS" },
  { name: "LC", name_ko: "LC" },
  { name: "LM", name_ko: "LM" },
  { name: "LS", name_ko: "LS" },
  { name: "LX", name_ko: "LX" },
  { name: "NX", name_ko: "NX" },
  { name: "RC", name_ko: "RC" },
  { name: "RX", name_ko: "RX" },
  { name: "RZ", name_ko: "RZ" },
  { name: "SC", name_ko: "SC" },
  { name: "UX", name_ko: "UX" }
];

const lexus = {
    icon: require('../../images/logos/lexus.png'),
    name: "Lexus",
    name_ko: "렉서스",
    models: models
}

export default lexus
import React, { useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Card, Spinner } from 'react-bootstrap';
import './styles.css';
import { formatMileage, getCarPhoto, getPrice } from '../../utils/CarUtils';
import { translateBrand, translateModel } from '../../services/translator';
import Header from './components/Header';
import useConversion from '../../hooks/useConversion';
import useFavorites from '../../hooks/useFavorites';
import Favorite from '@mui/icons-material/Favorite';
import { useDispatch } from 'react-redux';
import { removeFavorite } from '../../reducers/favorites';
import { removeFromFavorites } from '../../services/userInfo';
import { IconButton } from '@mui/material';
import { t } from 'i18next';
import SimpleHeader from '../../components/SimpleHeader';
import { ShimmerSimpleGallery } from 'react-shimmer-effects';


const Favorites = () => {
  const conversion = useConversion()
  const favorites = useFavorites()
  const dispatch = useDispatch()

  function handleFavorite(car) {
    const id = car.Id
    dispatch(removeFavorite(id))
    removeFromFavorites(car)
  }
  function handleCardPress(id) {
    window.open(`/car/${id}`, '_blank');
  }

  return (
    <>
      <SimpleHeader title={favorites ? `${favorites.length} ${t('cars')}` : ''} />
      <Container className="mt-0" style={{ paddingLeft: 0, paddingRight: 0 }}>
        {conversion && favorites ?
          <Row className='g-2 px-3 pb-3'>
            {favorites.map((car, index) => (
              <div key={car.Id}>
                <Card className="mb-0 car-card-link w-100" >
                  <div className="image-container">
                    <Card.Img variant="top" src={getCarPhoto(car?.Photo + "001.jpg")} alt={car.Model} onClick={() => handleCardPress(car.Id)} />
                    <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => handleFavorite(car)}>
                      <Favorite sx={{ color: 'red' }} />
                    </IconButton>

                  </div>
                  <Card.Body onClick={() => handleCardPress(car.Id)}>
                    <Card.Text className="car-big-title">{translateBrand(car.Manufacturer)} {translateModel(car.Manufacturer, car.Model)}</Card.Text>
                    <Card.Text className="car-big-year">{t('year')}: {car.FormYear}/{formatMileage(car.Mileage)}</Card.Text>
                    <Card.Text className="car-big-price">{getPrice(car.Price, conversion)}</Card.Text>
                    {/* <Link to={`/car/${car.id}`} className="btn btn-primary">View Details</Link> */}
                  </Card.Body>
                </Card>
              </div>
            ))}
            {favorites.length == 0 && <div className='no-result my-3'>{t("no_favorite")}</div>}

          </Row> :
          <Row className='g-2 px-3 pb-3'>
            <ShimmerSimpleGallery imageHeight={338.5} col={1} row={4} />
          </Row>
        }

      </Container>
    </>
  );
};

export default Favorites;

import React from "react";
import { useTranslation } from "react-i18next";

const BackIcon = (props) => {
    const {i18n} = useTranslation()

    return(
        <i {...props} className={props?.className+` fas fa-arrow-${i18n.language == "ar" ? 'right' : 'left'}`}></i>
    )
}


export default BackIcon
import { t } from "i18next"
import { maxPrice, minPrice } from "../components/SearchModal"

export function getCarPhoto(photoId) {
    if (!photoId) return ""
    //return `https://ci.encar.com${photoId}`
    // &rw=1100&cw=1100&ch=825&
    return `https://ci.encar.com${photoId}?impolicy=widthRate&rw=1160&cw=1160&ch=825&cg=Center&wtmk=https://ci.encar.com/wt_mark/w_mark_04.png`
}
const formatter = new Intl.NumberFormat('fr-FR', {
    style: 'currency',
    currency: 'MRU',
    maximumFractionDigits: 0

})


export function getPrice(defaultPrice, conversion, format = true) {
    const price = ((defaultPrice * 10000) + conversion.loadingFee + calculateProfit(defaultPrice, conversion.profit)) * conversion.rate
    if (format)
        return formatter.format(price).replace('MRU', t('mru'))
    else return price
}

function calculateProfit(price, baseProfit) {
    const increment = 1000;
    const levels = Math.floor(price / increment);
    if (levels <= 0) return baseProfit
    return levels * baseProfit;
}

export function getOriginalPrice(fullPriceInMRU, conversion) {
    const fullPriceInKrw = fullPriceInMRU / conversion.rate;

    const priceWithoutLoading = (fullPriceInKrw - conversion.loadingFee)

    const { price, profit } = extractProfitAndPrice(priceWithoutLoading, conversion.profit)
    return price / 10000
}
function extractProfitAndPrice(total, baseProfit) {
    const increment = 1000000;
    const profitPerIncrement = baseProfit;

    // Calculate the price by removing the benefit incrementally
    let price = total;
    while (price >= increment && (price % increment) === 0) {
        price -= profitPerIncrement;
    }
    price = price == total ? price - baseProfit : price

    // Calculate the benefit based on the adjusted price
    const profit = Math.floor(price / increment) * profitPerIncrement;

    // Return the sum of the recalculated price and benefit
    return { price: price, profit };
}

export function formatDate(dateStr) {
    return dateStr.substring(dateStr.length - 2, dateStr.length) + "/" + dateStr.substring(0, 4)
}

export function formatPrice(price) {
    return formatter.format(price).replace('MRU', t('mru'))
}
export function formatMileage(num) {
    var p = num.toFixed(0).split(".");
    return p[0].split("").reverse().reduce(function (acc, num, i, orig) {
        return num + (num != "-" && i && !(i % 3) ? "," : "") + acc;
    }, "") + t("km");
}

export function isDefaultDistanceRange(range) {
    return [10000, 500000].every((value, index) => value === range[index])
}
export function isDefaultPriceRange(range) {
    return [minPrice, maxPrice].every((value, index) => value === range[index])
}

export function isCarOnSale(value) {
    return value == undefined || value == "ADVERTISE"
}


const models = [
  { name: "G2X", name_ko: "G2X" },
  { name: "Nexia", name_ko: "넥시아" },
  { name: "Nubira", name_ko: "누비라" },
  { name: "Damas", name_ko: "다마스" },
  { name: "Lanos", name_ko: "라노스" },
  { name: "Labo", name_ko: "라보" },
  { name: "Lacetti", name_ko: "라세티" },
  { name: "Leganza", name_ko: "레간자" },
  { name: "Rezzo", name_ko: "레조" },
  { name: "Royal Saloon", name_ko: "로얄살롱" },
  { name: "LeMans", name_ko: "르망" },
  { name: "Matiz", name_ko: "마티즈" },
  { name: "Malibu", name_ko: "말리부" },
  { name: "Magnus", name_ko: "매그너스" },
  { name: "Mapsy", name_ko: "맵시" },
  { name: "Veritas", name_ko: "베리타스" },
  { name: "Bolt EV", name_ko: "볼트 EV" },
  { name: "Bolt EUV", name_ko: "볼트 EUV" },
  { name: "Volt", name_ko: "볼트(Volt)" },
  { name: "Brougham", name_ko: "브로엄" },
  { name: "Super Saloon", name_ko: "슈퍼살롱" },
  { name: "Statesman", name_ko: "스테이츠맨" },
  { name: "Spark", name_ko: "스파크" },
  { name: "Cielo", name_ko: "씨에로" },
  { name: "Aveo", name_ko: "아베오" },
  { name: "Arcadia", name_ko: "아카디아" },
  { name: "Alpheon", name_ko: "알페온" },
  { name: "Espero", name_ko: "에스페로" },
  { name: "Orlando", name_ko: "올란도" },
  { name: "Winstorm", name_ko: "윈스톰" },
  { name: "Equinox", name_ko: "이쿼녹스" },
  { name: "Impala", name_ko: "임팔라" },
  { name: "Imperial", name_ko: "임페리얼" },
  { name: "Gentra", name_ko: "젠트라" },
  { name: "Camaro", name_ko: "카마로" },
  { name: "Kalos", name_ko: "칼로스" },
  { name: "Captiva", name_ko: "캡티바" },
  { name: "Colorado", name_ko: "콜로라도" },
  { name: "Corvette", name_ko: "콜벳" },
  { name: "Cruze ", name_ko: "크루즈" },
  { name: "Tosca", name_ko: "토스카" },
  { name: "Traverse", name_ko: "트래버스" },
  { name: "Trax", name_ko: "트랙스" },
  { name: "Trailblazer", name_ko: "트레일블레이저" },
  { name: "Tico", name_ko: "티코" },
  { name: "Prince", name_ko: "프린스" },
  { name: "Tahoe", name_ko: "타호" }
];

const chevrolet_ko = {
  icon: require('../../images/logos/chevrolet.png'),
  name: "Chevrolet",
  name_ko: "쉐보레(GM대우)",
  models: models
}

export default chevrolet_ko
import React from 'react';
import PhoneIcon from '@mui/icons-material/Phone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import FacebookIcon from '@mui/icons-material/Facebook';
import MailIcon from '@mui/icons-material/Mail';
import { Button } from '@mui/material';
import SimpleHeader from '../../components/SimpleHeader';
import { t } from 'i18next';

const Contact = () => {
  const contactInfo = [
    {
      id: 1,
      label: t("phone"),
      value: "+82 10 2910 1962",
      icon: <PhoneIcon />,
      link: 'tel:+821029101962'
    },
    {
      id: 2,
      label: t("whatsapp"),
      value: "+82 10 2910 1962",
      icon: <WhatsAppIcon sx={{ color: '#25D366' }} />,
      link: 'https://wa.me/+821029101962'
    },
    {
      id: 3,
      label: t("facebook"),
      value: t("decoree"),
      icon: <FacebookIcon sx={{ color: '#3B5998' }} />,
      link: 'https://www.facebook.com/profile.php?id=61566792671064'
    },
    {
      id: 4,
      label: t("email"),
      value: "de.coree222@gmail.com",
      icon: <MailIcon />,
      link: "mailto:de.coree222@gmail.com"
    },
  ];

  return (
    <>
      <SimpleHeader title={t("contact")} />
      <div style={styles.container}>
        <div style={styles.contactList}>
          {contactInfo.map(info => (
            <Button key={info.id} sx={styles.contactItem} onClick={() => window.open(info.link, "_blank")}>
              <span className="material-icons" style={styles.icon}>{info.icon}</span>
              <div style={styles.contactDetails}>
                <strong>{info.label}:</strong>
                <div className='d-flex'> <div className='ltr-only'>{info.value}</div></div>
              </div>
            </Button>
          ))}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  contactList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid #ddd',
    borderRadius: '15px',
    padding: '10px',
    paddingLeft: '20px',
    backgroundColor: '#f5f5f5',
    textTransform: 'none',
    textAlign: 'start',
    color: '#000'
  },
  icon: {
    fontSize: '24px',
    color: '#333',
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Contact;

import { t } from 'i18next';
import bmw from './cars/bmw';
import chevrolet_ko from './cars/chevrolet_ko';
import hyundai from './cars/hyundai';
import jeep from './cars/jeep';
import kia from './cars/kia';
import samsung from './cars/samsung';
import toyota from './cars/toyota';
import fr_options from './options_fr.json'
import ar_options from './options_ar.json'
import genesis from './cars/genesis';
import benz from './cars/benz';
import kg_mobility from './cars/kg_mobility';
import audi from './cars/audi';
import ford from './cars/ford';
import nissan from './cars/nissan';
import porsche from './cars/porsche';
import mini from './cars/mini';
import land_rover from './cars/land_rover';
import volkswagen from './cars/volkswagen';
import lexus from './cars/lexus';

export const cars = [
    hyundai,
    chevrolet_ko,
    kia,
    samsung,
    genesis,
    kg_mobility,
    benz,
    toyota,
    bmw,
    jeep,
    audi,
    ford,
    nissan,
    porsche,
    mini,
    land_rover,
    volkswagen,
    lexus
]

export const fuelTypes = [{ name: t("diesel"), name_ko: "디젤" }, { name: t("gasoline"), name_ko: "가솔린" }, { name: t("electric"), name_ko: "전기" }, { name: t("gasoline")+" & "+t("electric"), name_ko: "가솔린+전기" }]
export const transmissions = [{ name: t("auto"), name_ko: "오토" }, { name: t("manual"), name_ko: "수동" }]

export function getYears() {
    const years = []
    for (var i = new Date().getFullYear(); i >= 1980; i--) {
        years.push(i)
    }
    return years
}

export function getMainOptions(lang) {
    const options = lang == 'ar' ? ar_options : fr_options
    const ids = [
        { code: "010", icon: require('../images/option_icons/sunroof.png') },
        { code: "029 075", icon: require('../images/option_icons/head_lamp_hid.png') },
        { code: "085 032", icon: require('../images/option_icons/parking_sensors.png') },
        { code: "058", icon: require('../images/option_icons/rear_camera.png') },
        { code: "023", icon: require('../images/option_icons/auto_air_conditioner.png') },
        { code: "057", icon: require('../images/option_icons/smart_key.png') },
        { code: "005", icon: require('../images/option_icons/navigation.png') },
        { code: "022 063", icon: require('../images/option_icons/warm_seat.png') },
        { code: "034 077", icon: require('../images/option_icons/cool_seat.png') },
        { code: "014", icon: require('../images/option_icons/leather_seat.png') }]

    const mainOptions = []

    ids.forEach((id) => {
        const code = id.code
        for (var j = 0; j < options.options.length; j++) {
            const option = options.options[j]
            if (!option.subOptions) {
                if (code.includes(option.optionCd)) {
                    mainOptions.push({ ...option, icon: id.icon })

                }
            } else {
                var added = false;
                option.subOptions.forEach((_subOption) => {
                    if (code.includes(_subOption.optionCd)) {
                        added = true
                    }
                })
                if (added) {
                    mainOptions.push({ ...option, icon: id.icon })
                    break
                }
            }
        }
    })
    return mainOptions
}
// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translation files
import en from './locales/en.json';
import fr from './locales/fr.json';
import ar from './locales/ar.json';

const browserLanguage = navigator.language || navigator.userLanguage;
const getDefLang = () => {
    const lang = browserLanguage.split('-')[0]
    return "ar,fr".includes(lang) ? lang : 'fr'
}
const savedLanguage = localStorage.getItem('language') || getDefLang();
// i18n configuration
i18n.use(initReactI18next).init({
    resources: {
        en: {
            translation: en,
        },
        fr: {
            translation: fr,
        },
        ar: {
            translation: ar,
        },
    },
    lng: savedLanguage, // Default language
    fallbackLng: 'fr', // Fallback language
    interpolation: {
        escapeValue: false, // react already safes from xss
    },
});

export default i18n;

import React, { useState } from "react";
import './styles.css';

const CustomDropdown = ({ title, options, selected, setSelected, selectable = true }) => {
    const [isOpen, setIsOpen] = useState(false);

    function handleOpen() {
        if (!selectable) return
        setIsOpen(!isOpen)
    }

    const handleOptionClick = (option) => {
        setSelected(option);
        setIsOpen(false);
    };


    return (
        <div className="custom-dropdown">
            <div className="dropdown-headers" onClick={handleOpen}>
                {selected || title}
                <i className={`fas ${isOpen ? 'fa-chevron-up' : 'fa-chevron-down'}`} style={{ fontSize: '0.65em' }}></i>
            </div>
            {isOpen && (
                <ul className="dropdown-lists">
                    {options.map((option, index) => (
                        <li key={index} className="dropdown-items" onClick={() => handleOptionClick(option)}>
                            {option?.icon && <img src={option.icon} style={{width: 30, height: 30, objectFit: 'contain'}} />}
                            <div style={{ flex: 1, marginLeft: 5, marginRight: 5 }}>{typeof option != "object" ? option : option.name}</div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default CustomDropdown
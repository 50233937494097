import React from 'react'

const DetailRow = ({label, value, children}) => {
    return (<div className="d-flex align-items-center">
        <p className="details flex-fill mb-0">{label}</p>
        {children}
        <p className="details_content mb-0">{value} </p>
    </div>)
}

export default DetailRow
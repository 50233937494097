import React, { useEffect, useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SimpleHeader from '../../components/SimpleHeader';
import { t } from 'i18next';
import { getFaqs } from '../../services/faqs';
import { Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const faqData = [
    { id: 1, question: "What is your return policy?", answer: "Our return policy lasts 30 days..." },
    { id: 2, question: "How do I track my order?", answer: "Once your order has been shipped..." },
    { id: 3, question: "Can I purchase items internationally?", answer: "Yes, we ship to over 200 countries..." }
];

const Faq = () => {
    const { i18n } = useTranslation()
    const [isLoading, setLoading] = useState(true)
    const [faqs, setFaqs] = useState()
    const [expandedId, setExpandedId] = useState(null); // Tracks which FAQ is expanded

    const toggleFaq = (id) => {
        setExpandedId(prevId => (prevId === id ? null : id));
    };

    useEffect(() => {
        getFaqs(i18n.language).then(setFaqs).finally(() => setLoading(false))
    }, [])

    return (
        <>
            <SimpleHeader title={t('faq')} />
            <div style={styles.container}>

                <div style={styles.faqList}>
                    {faqs ? faqs.map(faq => (
                        <div key={faq.id} style={styles.faqItem}>
                            <div
                                style={styles.question}
                                onClick={() => toggleFaq(faq.id)}
                            >
                                {faq.question}
                                <span style={styles.icon}>{expandedId === faq.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}</span>
                            </div>
                            {expandedId === faq.id && (
                                <div style={styles.answer}>{faq.answer}</div>
                            )}
                        </div>
                    )) :
                        <Spinner className='text-success spinner-grow spinner-border-sm' />
                    }
                </div>
            </div>
        </>
    );
};

const styles = {
    container: {
        width: '100%',
        maxWidth: '600px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    faqList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '10px',
        alignItems: 'center'
    },
    faqItem: {
        border: '1px solid #ddd',
        borderRadius: '5px',
        overflow: 'hidden',
        width: '100%'
    },
    question: {
        backgroundColor: '#f5f5f5',
        padding: '15px',
        cursor: 'pointer',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontWeight: 'bold',
    },
    answer: {
        padding: '15px',
        backgroundColor: '#fff',
        color: '#333',
    },
    icon: {
        marginLeft: '10px',
    },
};

export default Faq;

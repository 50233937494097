import React, { useState } from 'react';
import { Modal, Button } from 'react-bootstrap';
import './styles.css';
import { t } from 'i18next';



const SortModal = ({ sort = {}, show, defaultValue = { sortBy: "Price", sortDirection: "asc" }, handleClose, onConfirm }) => {
    const [value, setValue] = useState(defaultValue)

    function handleChange(e) {
        setValue(JSON.parse(e.target.value))
    }
    function handleConfirm() {
        onConfirm && onConfirm(value)
    }

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title style={{ fontSize: 18, flex: 1 }}>{t('sort_by')}</Modal.Title>
            </Modal.Header>
            <Modal.Body >
                {/* <RadioButton
                    label="Ajoutées récemment"
                    checked={value.sortBy == "Price" && value.sortDirection == "asc"}
                    value={{ sortBy: "Price", sortDirection: "asc" }}
                    onChange={handleChange}
                /> */}
                <RadioButton
                    label={t("cheapest")}
                    checked={value.sortBy == "Price" && value.sortDirection == "asc"}
                    value={JSON.stringify({ sortBy: "Price", sortDirection: "asc" })}
                    onChange={handleChange}
                />
                <RadioButton
                    label={t("most_expensive")}
                    checked={value.sortBy == "Price" && value.sortDirection == "desc"}
                    value={JSON.stringify({ sortBy: "Price", sortDirection: "desc" })}
                    onChange={handleChange}
                />
                <RadioButton
                    label={t("lowest_mileage")}
                    checked={value.sortBy == "Mileage" && value.sortDirection == "asc"}
                    value={JSON.stringify({ sortBy: "Mileage", sortDirection: "asc" })}
                    onChange={handleChange}
                />
                <RadioButton
                    label={t("highest_mileage")}
                    checked={value.sortBy == "Mileage" && value.sortDirection == "desc"}
                    value={JSON.stringify({ sortBy: "Mileage", sortDirection: "desc" })}
                    onChange={handleChange}
                />
                <RadioButton
                    label={t("latest_year")}
                    checked={value.sortBy == "FormYear" && value.sortDirection == "desc"}
                    value={JSON.stringify({ sortBy: "FormYear", sortDirection: "desc" })}
                    onChange={handleChange}
                />
                <RadioButton
                    label={t("recent")}
                    checked={value.sortBy == "UploadDate" && value.sortDirection == "desc"}
                    value={JSON.stringify({ sortBy: "UploadDate", sortDirection: "desc" })}
                    onChange={handleChange}
                />

            </Modal.Body>
            <Modal.Footer>
                <Button className="confirm-btn"
                    variant={"success"}
                    onClick={handleConfirm}
                    style={{ borderRadius: 25 }}>
                    {t('confirm')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

const RadioButton = (props) => {
    return (
        <div className='d-flex align-items-center py-1'>
            <input id={'ip' + JSON.stringify(props.value)} type="radio" {...props} />
            <label className='mx-2' htmlFor={"ip" + JSON.stringify(props.value)}>{props.label}</label>
        </div>
    )
}


export default SortModal;

const models = [
  { name: "CJ", name_ko: "CJ" },
  { name: "Gladiator", name_ko: "글래디에이터" },
  { name: "Wrangler", name_ko: "랭글러" },
  { name: "Renegade", name_ko: "레니게이드" },
  { name: "Avenger", name_ko: "어벤저" },
  { name: "Cherokee", name_ko: "체로키" },
  { name: "Commander", name_ko: "커맨더" },
  { name: "Compass", name_ko: "컴패스" },
  { name: "Patriot", name_ko: "패트리어트" }
];

const jeep = {
    icon: require('../../images/logos/jeep.png'),
    name: "Jeep",
    name_ko: "지프",
    models: models
}

export default jeep
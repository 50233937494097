import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';
import { Drawer, Box, List, ListItem, ListItemText, IconButton, ListItemButton } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Close from '@mui/icons-material/Close';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { getUserDisplayId, isLoggedIn, logout } from '../../../../services/auth';
import './styles.css'
import { useDispatch } from 'react-redux';
import { setFavorites } from '../../../../reducers/favorites';

const DrawerMenu = ({installPrompt}) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showInstallButton, setShowInstallButton] = useState(installPrompt != null);
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
  };

  const handleInstall = () => {
    if (installPrompt) {
        installPrompt.prompt(); // Show the install prompt
        installPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the install prompt');
            } else {
                console.log('User dismissed the install prompt');
            }
        });
    }else{
      window.location = "install"
    }
};

  function handleConnect() {
    if (isLoggedIn()) {
      dispatch(setFavorites([]))
      logout()

    }
    else navigate("login")
  }

  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng); 
  };


  return (
    <div>
      {/* Icon button={true} to open drawer */}
      <IconButton
        edge="start"
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon sx={{ color: '#000' }} />
      </IconButton>

      {/* Drawer component */}
      <Drawer open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box
          sx={{ width: 250 }}
          role="presentation"
          onClick={toggleDrawer(false)}
          onKeyDown={toggleDrawer(false)}
        >
          <List >
            <ListItem sx={{ flexDirection: 'column' }}>
              <div className='lang-container'>
                <Link className='lang-link'
                  onClick={() => changeLanguage('fr')}
                  style={{ color: currentLanguage == 'fr' ? '#198754' : '#000' }}>
                  FR
                </Link>
                |
                <Link
                  className='lang-link'
                  onClick={() => changeLanguage('ar')}
                  style={{ color: currentLanguage == 'ar' ? '#198754' : '#000' }}
                >
                  AR
                </Link>
              </div>
              <IconButton sx={{ alignSelf: 'end' }}>
                <Close sx={{ color: '#000' }} />
              </IconButton>
              {/* <ListItemText primary="Connectez-vous pour plus de confort" /> */}
              {isLoggedIn() ? (<><h5 className='welcome'>{t("welcome")} <br /><AccountCircleIcon /> <br /><div className="email ltr-only">{getUserDisplayId()}</div></h5></>) : <h5 className='mt-3'>{t("go_connect")}<br />{t("for_comfort")}</h5>}
              <ListItemButton sx={{ textDecoration: 'underline' }} onClick={handleConnect}>{isLoggedIn() ? t("disconnect") : t("connect")}</ListItemButton>
            </ListItem>
            <ListItem button={true} component={Link} to="/tutorial">
              <ListItemText primary={t("how_it_works")} sx={{ color: "#000" }} />
            </ListItem>
            {true && <ListItem button={true} component={Link} onClick={handleInstall}>
              <ListItemText primary={t("install")} sx={{ color: "#000" }} />
            </ListItem>}
            <ListItem button={true} component={Link} to={isLoggedIn() ? "/favorites" : "/login"}>
              <ListItemText primary={t("favorites")} sx={{ color: "#000" }} />
            </ListItem>
            <ListItem button={true} component={Link} to="/faq">
              <ListItemText primary={t("faq")} sx={{ color: "#000" }} />
            </ListItem>
            <ListItem button={true} component={Link} to="/contact">
              <ListItemText primary={t("contact")} sx={{ color: "#000" }} />
            </ListItem>
            <ListItem button={true} component={Link} to="/about-us">
              <ListItemText primary={t("about_us")} sx={{ color: "#000" }} />
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </div>
  );
};

export default DrawerMenu;

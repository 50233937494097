import React from 'react';
import './styles.css'; // Import custom CSS for styling
import { t } from 'i18next';


const SearchBar = ({onSearch}) => {

  return (
    <>
      <div className="search-bar mx-3 mb-4">
        <div
          className="search-input"
          onClick={onSearch}
        > {t("search_car")}
        </div>
        <button className="search-button" onClick={onSearch}>
          <i className="fas fa-search"></i> {/* FontAwesome search icon */}
        </button>
      </div>

    </>
  );
};

export default SearchBar;

import { useEffect, useState } from "react";

const useOS = ()=> {
    const [deviceOS, setDeviceOS] = useState(null);

    useEffect(() => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect iOS
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            setDeviceOS("iOS");
        }
        // Detect Android
        else if (/android/i.test(userAgent)) {
            setDeviceOS("Android");
        }
        // Detect Windows
        else if (/windows/i.test(userAgent)) {
            setDeviceOS("Windows");
        }
        // Detect macOS
        else if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(userAgent)) {
            setDeviceOS("macOS");
        }
        // Fallback for unknown OS
        else {
            setDeviceOS("Unknown OS");
        }
    }, []);
    
    return deviceOS
}
export default useOS
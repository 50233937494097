const models = [
  { name: "Cooper", name_ko: "쿠퍼" },
  { name: "Cooper Convertible", name_ko: "쿠퍼 컨버터블" },
  { name: "Coupé", name_ko: "쿠페" },
  { name: "Roadster", name_ko: "로드스터" },
  { name: "Clubman", name_ko: "클럽맨" },
  { name: "Countryman", name_ko: "컨트리맨" },
  { name: "Paceman", name_ko: "페이스맨" },
  { name: "Rover Mini", name_ko: "로버 미니" }
];

const mini = {
    icon: require('../../images/logos/mini.png'),
    name: "Mini",
    name_ko: "미니",
    models: models
}




export default mini
import { collection, getDocs, orderBy, query, where } from "firebase/firestore"
import { firestore } from "./firebase"

const faqsRef = collection(firestore, 'faq')
export function getFaqs(language){
    return new Promise((resolve, reject)=> {
        getDocs(query(faqsRef, where('language', '==', language), orderBy('order', 'asc')))
        .then((data)=> {
            const faqs = data.docs.map((doc) => ({id: doc.id, ...doc.data() }))
            resolve(faqs)
        }).catch(reject)
    })
}
const models = [
  { name: "Classe A", name_ko: "A-클래스" },
  { name: "Classe B (MY B)", name_ko: "B-클래스 (MY B)" },
  { name: "Classe C", name_ko: "C-클래스" },
  { name: "Classe CL", name_ko: "CL-클래스" },
  { name: "Classe CLA", name_ko: "CLA-클래스" },
  { name: "Classe CLE", name_ko: "CLE-클래스" },
  { name: "Classe CLK", name_ko: "CLK-클래스" },
  { name: "Classe CLS", name_ko: "CLS-클래스" },
  { name: "Classe E", name_ko: "E-클래스" },
  { name: "EQA", name_ko: "EQA" },
  { name: "EQB", name_ko: "EQB" },
  { name: "EQC", name_ko: "EQC" },
  { name: "EQE", name_ko: "EQE" },
  { name: "EQS", name_ko: "EQS" },
  { name: "Classe G (G-Wagen)", name_ko: "G-클래스 (G바겐)" },
  { name: "Classe GL", name_ko: "GL-클래스" },
  { name: "Classe GLA", name_ko: "GLA-클래스" },
  { name: "Classe GLB", name_ko: "GLB-클래스" },
  { name: "Classe GLC", name_ko: "GLC-클래스" },
  { name: "Classe GLE", name_ko: "GLE-클래스" },
  { name: "Classe GLK", name_ko: "GLK-클래스" },
  { name: "Classe GLS", name_ko: "GLS-클래스" },
  { name: "Classe M", name_ko: "M-클래스" },
  { name: "Classe R", name_ko: "R-클래스" },
  { name: "Classe S", name_ko: "S-클래스" },
  { name: "Classe SL", name_ko: "SL-클래스" },
  { name: "Classe SLC", name_ko: "SLC-클래스" },
  { name: "Classe SLK", name_ko: "SLK-클래스" },
  { name: "SLR", name_ko: "SLR" },
  { name: "SLS AMG", name_ko: "SLS AMG" },
  { name: "AMG GT", name_ko: "AMG GT" },
  { name: "SEL/SEC", name_ko: "SEL/SEC" },
  { name: "Classe V", name_ko: "V-클래스" },
  { name: "Sprinter", name_ko: "스프린터" },
  { name: "Classe 190", name_ko: "190-클래스" }
];

const benz = {
    icon: require('../../images/logos/benz.png'),
    name: "Mercedes Benz",
    name_ko: "벤츠",
    models: models
}

export default benz
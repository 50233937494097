
const models = [
    { name: "Runner", name_ko: "Runner" },
    { name: "86", name_ko: "86" },
    { name: "C-HR", name_ko: "C-HRFJ" },
    { name: "Cruiser", name_ko: "크루져" },
    { name: "MR-2", name_ko: "MR-2" },
    { name: "MR-S", name_ko: "MR-S" },
    { name: "RAV4", name_ko: "RAV4" },
    { name: "WiLL", name_ko: "WiLL" },
    { name: "bB", name_ko: "bB" },
    { name: "iQ", name_ko: "iQ" },
    { name: "Gaia", name_ko: "가이아" },
    { name: "Noah", name_ko: "노아" },
    { name: "Raum", name_ko: "라움" },
    { name: "Ractis", name_ko: "락티스" },
    { name: "Land Cruiser", name_ko: "랜드크루저" },
    { name: "Mark", name_ko: "마크" },
    { name: "Mark X", name_ko: "마크X" },
    { name: "Matrix", name_ko: "매트릭스" },
    { name: "Voxy", name_ko: "복시" },
    { name: "Verso", name_ko: "베르소" },
    { name: "Venza", name_ko: "벤자" },
    { name: "Vellfire", name_ko: "벨파이어" },
    { name: "Vista", name_ko: "비스타" },
    { name: "Sera", name_ko: "세라" },
    { name: "Sequoia", name_ko: "세콰이어" },
    { name: "Celica", name_ko: "셀리카" },
    { name: "Celsior", name_ko: "셀시오" },
    { name: "Soarer", name_ko: "소아라" },
    { name: "Solara", name_ko: "솔라라" },
    { name: "Supra", name_ko: "수프라" },
    { name: "Sienna", name_ko: "시에나" },
    { name: "Sienta", name_ko: "시엔타" },
    { name: "Avalon", name_ko: "아발론" },
    { name: "Aygo", name_ko: "아이고" },
    { name: "Altezza", name_ko: "알테자" },
    { name: "Alphard", name_ko: "알파드" },
    { name: "Xtra Cab", name_ko: "액스트라캡" },
    { name: "Yaris", name_ko: "야리스(비츠)" },
    { name: "Esquire", name_ko: "에스콰이어" },
    { name: "Estima", name_ko: "에스티마" },
    { name: "Wish", name_ko: "위시" },
    { name: "Ist", name_ko: "이스트" },
    { name: "Ipsum", name_ko: "입섬" },
    { name: "Chaser", name_ko: "체이서" },
    { name: "Carina", name_ko: "카리나" },
    { name: "Camry", name_ko: "캠리" },
    { name: "Corona", name_ko: "코로나" },
    { name: "Corolla", name_ko: "코롤라" },
    { name: "Corsa", name_ko: "코르사" },
    { name: "Crown", name_ko: "크라운" },
    { name: "Tacoma", name_ko: "타코마" },
    { name: "Tundra", name_ko: "툰드라" },
    { name: "Passo", name_ko: "파쏘" },
    { name: "Fun Cargo", name_ko: "펀 카고" },
    { name: "Forte", name_ko: "포르테" },
    { name: "Premio", name_ko: "프레미오" },
    { name: "Previa", name_ko: "프리비아" },
    { name: "Prius", name_ko: "프리우스" },
    { name: "Pickup Highlander", name_ko: "픽업하이랜더" },
    { name: "Hilux Surf", name_ko: "하이럭스 써프" },
    { name: "Hiace", name_ko: "하이에이스" },
    { name: "Harrier", name_ko: "해리어" },
    { name: "Highlander", name_ko: "하이랜더" }
  ];
  
  
const toyota = {
    icon: require('../../images/logos/toyota.png'),
    name: "Toyota",
    name_ko: "도요타",
    models: models
}


export default toyota
import { t } from "i18next";
import React from "react";

const CarUnavailable = ({status}) => {
    return(
        <div 
        style={{...styles.container, backgroundColor: `rgba(255, 255, 255, ${status == "CONTRACT" ? 0.1 : 0.5})`}} >
            <span style={styles.badge}>{status == "CONTRACT" ? t("reserved") : t("sold")}</span>
        </div>
    )
}

const styles = {
    container: {
        position: 'absolute', 
        backgroundColor: 'rgba(255, 255, 255, 0.5)', 
        width: '100%', 
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        pointerEvents: 'none',
        top: 0
    },
    badge: {
        backgroundColor: 'red',
        color: '#fff',
        fontWeight: 'bold',
        padding: 5,
        borderRadius: 5
    }
}
export default CarUnavailable
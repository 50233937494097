const models = [
  { name: "718", name_ko: "718" },
  { name: "911", name_ko: "911" },
  { name: "928", name_ko: "928" },
  { name: "944", name_ko: "944" },
  { name: "968", name_ko: "968" },
  { name: "Macan", name_ko: "마칸" },
  { name: "Boxster", name_ko: "박스터" },
  { name: "Carrera GT", name_ko: "카레라 GT" },
  { name: "Cayman", name_ko: "카이맨" },
  { name: "Cayenne", name_ko: "카이엔" },
  { name: "Taycan", name_ko: "타이칸" },
  { name: "Panamera", name_ko: "파나메라" }
];

const porsche = {
    icon: require('../../images/logos/porsche.png'),
    name: "Porsche",
    name_ko: "포르쉐",
    models: models
}



export default porsche
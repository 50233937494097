import React from 'react';
import { Navbar } from 'react-bootstrap';
import './styles.css'; // Import custom styles
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';
import BackIcon from '../../../../components/BackIcon';
import { Button } from '@mui/material';

const Header = ({ resultCount = 0, onSort }) => {
  const navigate = useNavigate()

  function handleBack() {
    navigate(-1)
  }
  return (
    <Navbar bg="white" className="header" sticky={'top'}>
      <Button className="icons" style={{color: '#000', fontSize: 22}} onClick={handleBack}>
        <BackIcon/> {/* Menu icon */}
      </Button>
      
      <div className="logo" style={{ flex: 1 }}>
        <h5 className='mb-0' style={{color: 'black'}}>{resultCount} {t('cars')}</h5>
      </div>

      <Navbar.Brand className="icons" style={{marginRight: 0}} onClick={() => { onSort && onSort() }}>
        <i className="fas fa-arrow-down-wide-short"></i>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Header;

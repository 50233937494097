import React from "react";
import "./styles.css";

const OptionRow = ({ label, checked }) => {
  return (
    <div className="text-checkbox-container">
      <span className="label" style={{color: checked ? "#198754" : "#888" }}>{label}</span>
      <i className={`fa${checked ? "s" : "r"} fa-circle-check`} style={{color: checked ? "#198754" : "#888" }}></i>
    </div>
  );
};

export default OptionRow;

const models = [
  { name: "Série 1", name_ko: "1시리즈" },
  { name: "Série 2", name_ko: "2시리즈" },
  { name: "Série 3", name_ko: "3시리즈" },
  { name: "Série 4", name_ko: "4시리즈" },
  { name: "Série 5", name_ko: "5시리즈" },
  { name: "Série 6", name_ko: "6시리즈" },
  { name: "Série 7", name_ko: "7시리즈" },
  { name: "Série 8", name_ko: "8시리즈" },
  { name: "Gran Turismo (GT)", name_ko: "그란투리스모 (GT)" },
  { name: "1M", name_ko: "1M" },
  { name: "M2", name_ko: "M2" },
  { name: "M3", name_ko: "M3" },
  { name: "M4", name_ko: "M4" },
  { name: "M5", name_ko: "M5" },
  { name: "M6", name_ko: "M6" },
  { name: "M8", name_ko: "M8" },
  { name: "X1", name_ko: "X1" },
  { name: "X2", name_ko: "X2" },
  { name: "X3", name_ko: "X3" },
  { name: "X4", name_ko: "X4" },
  { name: "X5", name_ko: "X5" },
  { name: "X6", name_ko: "X6" },
  { name: "X7", name_ko: "X7" },
  { name: "X3M", name_ko: "X3M" },
  { name: "X4M", name_ko: "X4M" },
  { name: "X5M", name_ko: "X5M" },
  { name: "X6M", name_ko: "X6M" },
  { name: "XM", name_ko: "XM" },
  { name: "Z3", name_ko: "Z3" },
  { name: "Z4", name_ko: "Z4" },
  { name: "Z8", name_ko: "Z8" },
  { name: "M Coupé/Roadster", name_ko: "M 쿠페/로드스터" },
  { name: "i3", name_ko: "i3" },
  { name: "i4", name_ko: "i4" },
  { name: "i5", name_ko: "i5" },
  { name: "i7", name_ko: "i7" },
  { name: "i8", name_ko: "i8" },
  { name: "iX1", name_ko: "iX1" },
  { name: "iX3", name_ko: "iX3" },
  { name: "iX", name_ko: "iX" }
];

const bmw = {
    icon: require('../../images/logos/bmw.png'),
    name: "BMW",
    name_ko: "BMW",
    models: models
}

export default bmw
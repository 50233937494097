import React from 'react';
import { Navbar } from 'react-bootstrap';
import './styles.css'; // Import custom styles
import { useNavigate } from 'react-router-dom';
import BackIcon from '../BackIcon';
import { Button } from '@mui/material';

const SimpleHeader = ({ title }) => {
  const navigate = useNavigate()

  function handleBack() {
    navigate(-1)
  }
  return (
    <Navbar bg="white" className="header" sticky={'top'}>
      <Button className="icons" sx={{fontSize: 22, color: '#000'}} onClick={handleBack}>
        <BackIcon/>
      </Button>
      
      <Navbar.Text className="logo" style={{ flex: 1 }}>
        <h5 className='mb-0' style={{color: 'black'}}>{title}</h5>
      </Navbar.Text>

      <Navbar.Brand className="icons">
        <i></i>
      </Navbar.Brand>
    </Navbar>
  );
};

export default SimpleHeader;

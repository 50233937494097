import { useState, useEffect, useRef } from "react";
import { query, limit, startAfter, getDocs } from "firebase/firestore";

const useCars = (initialQuery, onData, onLoading, onError, deps = []) => {
    const [docs, setDocs] = useState([]);
    const lastDoc = useRef()
    const [hasMore, setHasMore] = useState(true);
    const [loading, setLoading] = useState(false);
    const lastDocInProgress = useRef()

    
    const fetchDocuments = async (initial = false) => {
       if(lastDoc.current != null && lastDoc.current == lastDocInProgress.current) {
        console.log("not handled")
        return
       }
        setLoading(true);
        lastDocInProgress.current = lastDoc.current
        onLoading && onLoading(true)
        try {
            let q = query(initialQuery, limit(10));

            if (!initial && lastDoc.current) {
                q = query(
                    initialQuery,
                    startAfter(lastDoc.current),
                    limit(20)
                );
            }

            const documentSnapshots = await getDocs(q);

            if (!documentSnapshots.empty) {
                const newDocs = documentSnapshots.docs.map(doc => doc.data());
                
                setDocs((prevDocs) => {
                    if(prevDocs.length > 0 && prevDocs[prevDocs.length - 1]?.Id == newDocs[newDocs.length - 1]?.Id){
                        return prevDocs
                    }else{
                        const allDocs = [...prevDocs, ...newDocs]
                        onData(allDocs);
                        return allDocs
                    }
                    
                   
                })

                const lastVisible = documentSnapshots.docs[documentSnapshots.docs.length - 1];
                lastDoc.current = lastVisible

            } else {
                setHasMore(false);
            }
        } catch (error) {
            onError && onError(error)
        } finally {
            setLoading(false);
            onLoading && onLoading(false)
        }
    };

    useEffect(() => {
        fetchDocuments(true);
    }, deps);

    const handleScroll = () => {
        
        const scrollPosition = window.innerHeight + document.documentElement.scrollTop;
        const threshold = document.documentElement.offsetHeight - 200; // Adjust threshold here (200px from the bottom)

        if (scrollPosition >= threshold && hasMore && !loading) {
            fetchDocuments();
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [hasMore, loading]);
};

export default useCars;



const models = [
    { name: "K3", name_ko: "K3" },
    { name: "K5", name_ko: "K5" },
    { name: "K7", name_ko: "K7" },
    { name: "K8", name_ko: "K8" },
    { name: "K9", name_ko: "K9" },
    { name: "EV3", name_ko: "EV3" },
    { name: "EV6", name_ko: "EV6" },
    { name: "EV9", name_ko: "EV9" },
    { name: "X-TREK", name_ko: "X-TREK" },
    { name: "Niro", name_ko: "니로" },
    { name: "Delta", name_ko: "델타" },
    { name: "Ray", name_ko: "레이" },
    { name: "Retona", name_ko: "레토나" },
    { name: "Lotze", name_ko: "로체" },
    { name: "Rocsta", name_ko: "록스타" },
    { name: "Regal", name_ko: "리갈" },
    { name: "Rio", name_ko: "리오" },
    { name: "Morning", name_ko: "모닝" },
    { name: "Mohave", name_ko: "모하비" },
    { name: "Vesta", name_ko: "베스타" },
    { name: "Bongo III Minibus", name_ko: "봉고III 미니버스" },
    { name: "Brisa", name_ko: "브리샤" },
    { name: "Visto", name_ko: "비스토" },
    { name: "Sephia", name_ko: "세피아" },
    { name: "Seltos", name_ko: "셀토스" },
    { name: "Shuma", name_ko: "슈마" },
    { name: "Stonic", name_ko: "스토닉" },
    { name: "Stinger", name_ko: "스팅어" },
    { name: "Spectra", name_ko: "스펙트라" },
    { name: "Sportage", name_ko: "스포티지" },
    { name: "Cerato", name_ko: "쎄라토" },
    { name: "Sorento", name_ko: "쏘렌토" },
    { name: "Soul", name_ko: "쏘울" },
    { name: "Ceed", name_ko: "씨드" },
    { name: "Avella", name_ko: "아벨라" },
    { name: "Enterprise", name_ko: "엔터프라이즈" },
    { name: "Elan", name_ko: "엘란" },
    { name: "Opirus", name_ko: "오피러스" },
    { name: "Optima", name_ko: "옵티마" },
    { name: "Carnival", name_ko: "카니발" },
    { name: "Carens", name_ko: "카렌스" },
    { name: "Casta", name_ko: "카스타" },
    { name: "Capital", name_ko: "캐피탈" },
    { name: "Concord", name_ko: "콩코드" },
    { name: "Credos", name_ko: "크레도스" },
    { name: "Towner", name_ko: "타우너" },
    { name: "Topic", name_ko: "토픽" },
    { name: "Telluride", name_ko: "텔루라이드" },
    { name: "Parktown", name_ko: "파크타운" },
    { name: "Forte", name_ko: "포르테" },
    { name: "Potentia", name_ko: "포텐샤" },
    { name: "Pride", name_ko: "프라이드" },
    { name: "Pregio", name_ko: "프레지오" },
    { name: "Fiat", name_ko: "피아트" }
];

const kia = {
    icon: require('../../images/logos/kia.png'),
    name: "Kia",
    name_ko: "기아",
    models: models
}

export default kia
import React from 'react';
import { Navbar } from 'react-bootstrap';
import './styles.css'; // Import custom styles
import { IconButton } from '@mui/material';
import Favorite from '@mui/icons-material/Favorite'
import FavoriteOutline from '@mui/icons-material/FavoriteBorder'
import Share from '@mui/icons-material/ShareOutlined'
import useFavorites from '../../../../hooks/useFavorites';
import { isLoggedIn } from '../../../../services/auth';
import { useDispatch } from 'react-redux';
import { addToFavorites, removeFromFavorites } from '../../../../services/userInfo';
import { addFavorite, removeFavorite } from '../../../../reducers/favorites';
import { t } from 'i18next';
import BackIcon from '../../../../components/BackIcon';

const Header = ({ car }) => {
  const dispatch = useDispatch()
  const favorites = useFavorites()

  function handleFavorite() {

    if (!isLoggedIn()) return
    const id = car.Id

    const hasFavorite = favorites && favorites.some(item => item.Id === id);

    if (hasFavorite) {
      dispatch(removeFavorite(id))
      removeFromFavorites(car)
    } else {
      dispatch(addFavorite(car))
      addToFavorites(car)
    }
  }

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('look'),
          text: t('check_out_this_car'),
          url: window.location.href,
        });

      } catch (error) {
        console.error('Error sharing:', error);
      }
    } else {
      alert(t('feature_unavailable'))
      console.log('Web Share API not supported in this browser.');
    }
  };

  function handleGoBack() {
    window.close()
  }
  return (
    <Navbar bg="white" sticky={'top'} style={{paddingLeft: '10px', paddingRight: '10px'}}>

        <IconButton  onClick={handleGoBack}>
          <BackIcon className="px-1" style={{color: '#000', fontSize: 22}}></BackIcon> {/* Menu icon */}
        </IconButton>

      <div style={{ flex: 1 }} />

      <Navbar.Brand className="car-detail-icon" style={{ marginRight: 2 }}>
        <IconButton onClick={handleFavorite}>
          {favorites && favorites.some(item => item.Id === car?.Id) ? <Favorite sx={{ color: 'red' }} /> : <FavoriteOutline sx={{ color: '#000' }} />}
        </IconButton>
      </Navbar.Brand>

      <Navbar.Brand className="car-detail-icon" style={{ marginRight: 5 }} >
        <IconButton onClick={handleShare}>
          <Share sx={{ color: '#000' }} />
        </IconButton>
      </Navbar.Brand>
    </Navbar>
  );
};

export default Header;

import { IconButton } from '@mui/material';
import React from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import FavoriteOutline from '@mui/icons-material/FavoriteBorder';
import Favorite from '@mui/icons-material/Favorite';
import { translateBrand, translateModel } from '../../../../services/translator';
import { getCarPhoto, getPrice, isCarOnSale } from '../../../../utils/CarUtils';
import useConversion from '../../../../hooks/useConversion';
import { ShimmerSimpleGallery } from "react-shimmer-effects";
import { t } from 'i18next';
import CarUnavailable from '../../../../components/CarUnavailable';

const GridCars = ({ cars, onFavorite, favorites = new Set() }) => {
    const conversion = useConversion()
    function handleCardPress(id) {
        window.open(`/car/${id}`, '_blank');
    }
    
    return (
        <Row className='g-2 px-3 pb-3 mb-3' style={{minHeight: 300}}>
            {conversion ? cars.map((car, index) => (
                <Col key={car.Id} xs={6} sm={6} md={6} className="d-flex justify-content-center">

                    <Card className="h-100" style={{ width: '18rem' }}>
                        <div className="image-container">
                            <Card.Img variant="top" src={getCarPhoto(car?.Photos[0]?.location)} alt={car.Model} onClick={() => handleCardPress(car.Id)} />
                            <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => onFavorite(car)}>
                                {favorites && favorites.some(item => item.Id === car.Id) ? <Favorite sx={{ color: 'red' }} /> : <FavoriteOutline sx={{ color: '#fff' }} />}
                            </IconButton>
                            {!isCarOnSale(car.SalesStatus) && <CarUnavailable status={car.SalesStatus}/>}
                        </div>
                        <Card.Body style={{ padding: 10 }} onClick={() => handleCardPress(car.Id)}>
                            <Card.Text className="car-title">{translateBrand(car.Manufacturer)} {translateModel(car.Manufacturer, car.Model)}</Card.Text>
                            <div style={{ flex: 1 }}></div>
                            <Card.Text className="car-year">{t("year")}: {car.FormYear}</Card.Text>
                            <Card.Text className="car-mileage">{car.Mileage} {t("km")}</Card.Text>
                            <Card.Text className="car-price">{getPrice(car.Price, conversion)}</Card.Text>
                            {/* <Link to={`/car/${car.id}`} className="btn btn-primary">View Details</Link> */}
                        </Card.Body>
                        
                    </Card>
                    

                </Col>
            )) : cars.length == 0 ? <ShimmerSimpleGallery imageHeight={186} col={2} row={2}/> : null}
        </Row>
    );
};

export default GridCars;

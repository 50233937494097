import React, { useState } from 'react';
import { auth, RecaptchaVerifier, signInWithPhoneNumber, signInWithCredential, PhoneAuthProvider } from '../../services/firebase';
import { Button } from '@mui/material';
import { Card, Container, Spinner } from 'react-bootstrap';
import './styles.css'
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';

const PhoneLogin = () => {
  const [isLoading, setLoading] = useState(false)
  const [phoneNumber, setPhoneNumber] = useState('');
  const [otp, setOtp] = useState('');
  const [verificationId, setVerificationId] = useState(null);
  const [error, setError] = useState('');
  const navigate = useNavigate()

  // Initialize reCAPTCHA for phone number authentication
  const setupRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = new RecaptchaVerifier(auth,
        'recaptcha-container',  // This ID must match the div in your JSX
        {
          size: 'invisible',  // Can be 'invisible' or 'normal'
          callback: (response) => {
            // reCAPTCHA solved, you can now proceed with phone auth
            console.log('reCAPTCHA verified');
          },
        },
        auth // Pass the initialized Firebase Auth instance
      );
    }
  };


  // Function to handle phone number sign in
  const signInWithPhone = () => {
    setupRecaptcha();
    const appVerifier = window.recaptchaVerifier;
    setLoading(true)
    signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(confirmationResult => {
        // Store the verificationId to later verify the OTP
        setVerificationId(confirmationResult.verificationId);
        setError('');
      })
      .catch(error => {
        setError(t('error_sending_code'));
      }).finally(() => setLoading(false));
  };

  // Function to verify OTP
  const verifyOtp = () => {
    const credential = PhoneAuthProvider.credential(verificationId, otp);
    setLoading(true)
    signInWithCredential(auth, credential)
      .then(result => {
        navigate('/')
        setError("")
      })
      .catch(error => {
        setError(t('invalid_code'));
      }).finally(() => setLoading(false));
  };


  return (
    <Container className='d-flex flex-column align-items-center justify-content-center login-container'>
      <img src={require('../../images/login_bgd.png')} className='bgd' />
      <img src={require('../../images/logo.png')} className='login_logo' />
      <span className='slogan'>{t('slogan')}</span>
      <div id="recaptcha-container"></div>
      <Card className='align-items-center px-4 py-4 mt-4' style={{ borderRadius: 25 }}>
        <h3 className=' pb-4'>{t('sign_in_by_phone')}</h3>
        {verificationId ? (
          <input
            type="text"
            placeholder={t('type_code')}
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            className='phone-input ltr-only'
          />
        ) :
          <input
            type={"tel"}
            placeholder={"+222 40 00 00 00"}
            value={phoneNumber}
            className='phone-input ltr-only'
            onChange={(e) => setPhoneNumber(e.target.value)}
          />}
        <Button className='login-button px-5 mt-4'
          sx={{ borderRadius: 100, backgroundColor: '#00966B', textTransform: 'none', fontSize: 16 }}
          variant='contained'
          onClick={verificationId ? verifyOtp : signInWithPhone}>
          {verificationId ? t("check") : t("send_code")}
        </Button>
        {error && <p className='mt-2' style={{ color: 'red' }}>{error}</p>}
        {isLoading && <div className='d-flex justify-content-center align-items d-fill mt-2'>
          <Spinner className='text-success spinner-grow spinner-border-sm' />
        </div>}
      </Card>

    </Container>
  );
};

export default PhoneLogin;

const models = [
  { name: "New Family", name_ko: "뉴훼미리" },
  { name: "Rexton", name_ko: "렉스턴" },
  { name: "Rodius", name_ko: "로디우스" },
  { name: "Musso", name_ko: "무쏘" },
  { name: "Actyon", name_ko: "액티언" },
  { name: "Istana", name_ko: "이스타나" },
  { name: "Chairman", name_ko: "체어맨" },
  { name: "Kalista", name_ko: "칼리스타" },
  { name: "Kyron", name_ko: "카이런" },
  { name: "Korando", name_ko: "코란도" },
  { name: "Torres", name_ko: "토레스" },
  { name: "Tivoli", name_ko: "티볼리" }
];

const kg_mobility = {
    icon: require('../../images/logos/kg_group.png'),
    name: "KG Mobility (Ssangyong)",
    name_ko: "KG모빌리티(쌍용)",
    models: models
}

export default kg_mobility
const models = [
  { name: "Discovery Sport", name_ko: "디스커버리 스포츠" },
  { name: "Discovery", name_ko: "디스커버리" },
  { name: "Defender", name_ko: "디펜더" },
  { name: "Range Rover Velar", name_ko: "레인지로버 벨라" },
  { name: "Range Rover Sport", name_ko: "레인지로버 스포츠" },
  { name: "Range Rover Evoque", name_ko: "레인지로버 이보크" },
  { name: "Range Rover", name_ko: "레인지로버" },
  { name: "Freelander", name_ko: "프리랜더" }
];

const land_rover = {
    icon: require('../../images/logos/land_rover.png'),
    name: "Land Rover",
    name_ko: "랜드로버",
    models: models
}

export default land_rover
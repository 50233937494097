import React, { useState } from "react";
import ReactSlider from "react-slider";
import './styles.css';
import { formatPrice } from "../../utils/CarUtils";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

const RangeSlider = ({title, value, onChange, suffix = "", min, max, step}) => {
    const {i18n} = useTranslation()
    const [currentValue, setCurrentValue] = useState([value[0], value[1]])

    function handleChange(value){
        setCurrentValue(value)
        onChange && onChange(value)
    }
    return (
        <div className="distance-range-slider">
            <label>{title}</label>

            {/* Display the min and max distance at the top */}
            <div className="d-flex justify-content-between mb-2 ltr-only">
                <span className={`slider-value ${i18n.language == "ar" ? 'rtl': ''}`}>{suffix === t("mru") ? formatPrice(currentValue[0]) : currentValue[0]+suffix} </span>
                <span className={`slider-value ${i18n.language == "ar" ? 'rtl': ''}`}>{suffix === t("mru") ? formatPrice(currentValue[1]) : currentValue[1]+ suffix}</span>
            </div>

            {/* Range Slider with two handles */}
            <ReactSlider
                className="horizontal-slider"
                thumbClassName="thumb"
                trackClassName="track"
                min={min}
                max={max}
                step={step}
                value={value}
                onChange={handleChange}
                withTracks={true} // Highlight the track between the two handles
                pearling // Prevents the handles from crossing each other
            />
        </div>
    )
}
export default RangeSlider
const models = [
  { name: "CC", name_ko: "CC" },
  { name: "EOS", name_ko: "EOS" },
  { name: "ID.4", name_ko: "ID.4" },
  { name: "Golf", name_ko: "골프" },
  { name: "Rialta", name_ko: "리알타" },
  { name: "Microbus", name_ko: "마이크로버스" },
  { name: "Vento", name_ko: "벤토" },
  { name: "Bora", name_ko: "보라" },
  { name: "Beetle", name_ko: "비틀" },
  { name: "Sharan", name_ko: "사란" },
  { name: "Scirocco", name_ko: "시로코" },
  { name: "Arteon", name_ko: "아테온" },
  { name: "Atlas", name_ko: "아틀라스" },
  { name: "Jetta", name_ko: "제타" },
  { name: "Corrado", name_ko: "코라도" },
  { name: "Touareg", name_ko: "투아렉" },
  { name: "Transporter", name_ko: "트랜스포터" },
  { name: "Tiguan", name_ko: "티구안" },
  { name: "T-Roc", name_ko: "티록" },
  { name: "Passat", name_ko: "파사트" },
  { name: "Phaeton", name_ko: "페이톤" },
  { name: "Polo", name_ko: "폴로" }
];

const volkswagen = {
    icon: require('../../images/logos/volkswagen.png'),
    name: "Volkswagen",
    name_ko: "폭스바겐",
    models: models
}



export default volkswagen
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getConversionData } from "../services/conversion";
import { setConversion } from "../reducers/conversion";
import { isAnHourAgo } from "../utils/generic";

function useConversion(){
    const conversion = useSelector(state => state.conversion.value)
    const dispatch = useDispatch()

    useEffect(()=> {
        if(!conversion || isAnHourAgo(conversion.lastUpdate)){
            getConversionData().then(data => {
                dispatch(setConversion(data))
            })
        }
    }, [])

    return conversion
}

export default useConversion
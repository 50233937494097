import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import fr_options from '../../../../data/options_fr.json'
import ar_options from '../../../../data/options_ar.json'

import OptionRow from '../OptionRow';
import './styles.css'
import { useTranslation } from 'react-i18next';

const OptionsModal = ({ show, handleClose, options }) => {
    const { t, i18n } = useTranslation();
    const currentLanguage = i18n.language;

    function getAllOptions(){
        return currentLanguage == "ar" ? ar_options : fr_options
    }
    if (options)
        return (
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Header closeButton >
                    <Modal.Title>{t("options")}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-body-scrollable">
                    {getAllOptions().metas.map((meta, i) => {

                        return <div key={i}>
                            <label className='mt-2'>{meta.value}</label>
                            {getAllOptions().options.map((option, index) => {
                                if (option.optionTypeCd == meta.key) {
                                    var hasOption = false
                                    if (option.subOptions) {
                                        for (var j = 0; j < option.subOptions.length; j++) {
                                            const subOption = option.subOptions[j]
                                            hasOption = options.standard.includes(subOption.optionCd)

                                            if (hasOption) {
                                                option = { ...subOption, icon: option.icon }
                                                return (
                                                    <OptionRow label={option.optionName} checked={hasOption} key={index + "" + j} />
                                                )
                                            }
                                        }


                                    } else {
                                        hasOption = options.standard.includes(option.optionCd)
                                        return (
                                            <OptionRow label={option.optionName} checked={hasOption} key={index} />
                                        )
                                    }

                                }

                            })}
                        </div>
                    })}
                </Modal.Body>
            </Modal>
        );
};

export default OptionsModal;

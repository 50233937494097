import React from "react";
import './styles.css'
import { IconButton } from "@mui/material";
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import InstagramIcon from '@mui/icons-material/Instagram';
import { t } from "i18next";

const Footer = () => {
    return (
        <div className="footer-container my-2">

            <div className="d-flex">
                <IconButton onClick={()=> window.open(`https://wa.me/+821029101962`, '_blank')}>
                    <WhatsAppIcon />
                </IconButton>
                <IconButton onClick={()=> window.open(`https://www.facebook.com/profile.php?id=61566792671064`, '_blank')}>
                    <FacebookIcon />
                </IconButton>

                <IconButton>
                    <InstagramIcon />
                </IconButton>
            </div>
            <span className="footer-detail">{t("rights")}</span>
        </div>
    )
}

export default Footer
const models = [
  { name: "100", name_ko: "100" },
  { name: "80", name_ko: "80" },
  { name: "90", name_ko: "90" },
  { name: "A1", name_ko: "A1" },
  { name: "A3", name_ko: "A3" },
  { name: "A4", name_ko: "A4" },
  { name: "A5", name_ko: "A5" },
  { name: "A6", name_ko: "A6" },
  { name: "A7", name_ko: "A7" },
  { name: "A8", name_ko: "A8" },
  { name: "Q2", name_ko: "Q2" },
  { name: "Q3", name_ko: "Q3" },
  { name: "Q5", name_ko: "Q5" },
  { name: "Q7", name_ko: "Q7" },
  { name: "Q8", name_ko: "Q8" },
  { name: "R8", name_ko: "R8" },
  { name: "RS3", name_ko: "RS3" },
  { name: "RS4", name_ko: "RS4" },
  { name: "RS5", name_ko: "RS5" },
  { name: "RS6", name_ko: "RS6" },
  { name: "RS7", name_ko: "RS7" },
  { name: "RSQ", name_ko: "RSQ" },
  { name: "S3", name_ko: "S3" },
  { name: "S4", name_ko: "S4" },
  { name: "S5", name_ko: "S5" },
  { name: "S6", name_ko: "S6" },
  { name: "S7", name_ko: "S7" },
  { name: "S8", name_ko: "S8" },
  { name: "SQ", name_ko: "SQ" },
  { name: "SQ7", name_ko: "SQ7" },
  { name: "SQ8", name_ko: "SQ8" },
  { name: "TTRS", name_ko: "TTRS" },
  { name: "TTS", name_ko: "TTS" },
  { name: "TT", name_ko: "TT" },
  { name: "V8", name_ko: "V8" },
  { name: "Q4 e-tron", name_ko: "Q4 e-트론" },
  { name: "Q8 e-tron", name_ko: "Q8 e-트론" },
  { name: "e-tron", name_ko: "e-트론" },
  { name: "e-tron GT", name_ko: "e-트론 GT" },
  { name: "RS e-tron GT", name_ko: "RS e-트론 GT" },
  { name: "Allroad Quattro", name_ko: "올로드 콰트로" }
];

const audi = {
    icon: require('../../images/logos/audi.png'),
    name: "Audi",
    name_ko: "아우디",
    models: models
}



export default audi
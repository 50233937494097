import React, { useState } from 'react';
import '../../styles.css'
import { formatPrice, getPrice } from '../../../../utils/CarUtils';
import HelpIcon from '@mui/icons-material/Help';
import { Checkbox, IconButton } from '@mui/material';
import { t } from 'i18next';

const PricingDetails = ({ price, conversion }) => {
  // Sample prices
  const [collapsed, setCollapsed] = useState(false)
  const [deliveryFee, setDeliveryFee] = useState(500); // Example delivery fee
  const [customsFee, setCustomsFee] = useState(800); // Example customs fee
  const [includeCustoms, setIncludeCustoms] = useState(true); // Checkbox state

  // Calculate total based on checkbox state
  const total = formatPrice(conversion.transFee + (includeCustoms ? conversion.customsFee : 0) + getPrice(price, conversion, false))

  return (
    <div style={styles.container}>
      {/* Price Section */}
      <div className='d-flex justify-space-between'  >
        <div style={{...styles.section, flex: 1}}>
          <h3 style={styles.title} className='details'>{t('car_price')}</h3>
          <span style={styles.value}>{getPrice(price, conversion)}</span>
        </div>
        <IconButton onClick={() => window.open('/tutorial', '_blank')}>
          <HelpIcon />
        </IconButton>
      </div>


      {/* Delivery Section */}
      <div style={styles.section}>
        <h3 style={styles.title} className='details'>{t("delivery")}</h3>
        <div style={styles.subsection}>
          <span className='details'>{t('fee')} : <span style={styles.value}>{formatPrice(conversion.transFee)}</span></span>
          <span className='details'>{t('duration')} : <span style={styles.value}>{t('duration_span')}</span></span>
        </div>
      </div>

      {/* Customs Section */}
      <div style={styles.section}>
        <h3 style={styles.title} className='details'>{t("customs")}</h3>
        <div style={styles.subsection}>
          <span className='details'>{t('fee')} : <span style={styles.value}>~ {formatPrice(conversion.customsFee)}</span></span>
          <label style={styles.checkboxLabel}>
            <Checkbox
              type="checkbox"
              checked={includeCustoms}
              color='success'
              onChange={() => setIncludeCustoms(!includeCustoms)}
              size='small'
              sx={{ height: 20 }}
            />
            {t('include_clearance')}
          </label>
        </div>
      </div>

      {/* Total Section */}
      <div style={{ ...styles.section, borderBottomWidth: 0 }}>
        <h3 style={styles.title} className='details'>Total</h3>
        <span style={{ ...styles.value, fontSize: 18 }}>{total}</span>
      </div>

    </div>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '400px',
    margin: '0 auto',
    borderRadius: '8px',
    fontFamily: 'Arial, sans-serif',
  },
  section: {
    marginBottom: '5px',
    borderBottom: '1px solid #ddd',
    paddingBottom: '2px'
  },
  title: {
    marginBottom: '2px'
  },
  subsection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  value: {
    fontSize: '16px',
    fontWeight: 'bold',
    color: '#000',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#555',
  },
};

export default PricingDetails;

import React from 'react';
import SimpleHeader from '../../components/SimpleHeader';
import { t } from 'i18next';
import useOS from '../../hooks/useOS';
import { useTranslation } from 'react-i18next';

const Installation = () => {
  const deviceOS = useOS()
  const { i18n } = useTranslation()

  return (
    <>
      <SimpleHeader title={t("install")} />
      <div style={styles.container}>
        {deviceOS == "iOS" ? <div style={styles.contactList}>

          <img
            src={i18n.language == "ar" ? require('./images/iphone_1_ar.png') : require('./images/iphone_1_fr.png')}
            style={{ width: '80vw', height: 'auto' }}
          />
          <img
            src={i18n.language == "ar" ? require('./images/iphone_2_ar.png') : require('./images/iphone_2_fr.png')}
            style={{ width: '80vw', height: 'auto' }}
          />
          <img
            src={i18n.language == "ar" ? require('./images/iphone_3_ar.png') : require('./images/iphone_3_fr.png')}
            style={{ width: '80vw', height: 'auto' }}
          />
        </div> :
          <div style={styles.contactList}>
            <img
              src={i18n.language == "ar" ? require('./images/android_1_ar.png') : require('./images/android_1_fr.png')}
              style={{ width: '80vw', height: 'auto' }}
            />
            <img
              src={i18n.language == "ar" ? require('./images/android_2_ar.png') : require('./images/android_2_fr.png')}
              style={{ width: '80vw', height: 'auto' }}
            />
            <img
              src={i18n.language == "ar" ? require('./images/android_3_ar.png') : require('./images/android_3_fr.png')}
              style={{ width: '80vw', height: 'auto' }}
            />
          </div>}
      </div>
    </>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  contactList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid #ddd',
    borderRadius: '15px',
    padding: '10px',
    paddingLeft: '20px',
    backgroundColor: '#f5f5f5',
    textTransform: 'none',
    textAlign: 'start',
    color: '#000'
  },
  icon: {
    fontSize: '24px',
    color: '#333',
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default Installation;

import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import SimpleHeader from '../../components/SimpleHeader';
import { t } from 'i18next';

const HowItWorks = () => {
    const steps = [
        {
            title: t('step1_title'),
            description: t('step1_description'),
            icon: <SearchIcon />,
        },
        {
            title: t('step2_title'),
            description: t('step2_description'),
            icon: <CheckCircleIcon />,
        },
        {
            title: t('step3_title'),
            description: t('step3_description'),
            icon: <MonetizationOnIcon />,
        },
        {
            title: t('step4_title'),
            description: t('step4_description'),
            icon: <LocalShippingIcon />,
        },
        {
            title: t('step5_title'),
            description: t('step5_description'),
            icon: <AccountBalanceIcon />,
        },
        {
            title: t('step6_title'),
            description: t('step6_description'),
            icon: <AttachMoneyIcon />,
        },
    ];
    

    return (
        <>
            <SimpleHeader title={t("how_it_works")} />
            <div style={styles.container}>
                <div style={styles.stepsList}>
                    {steps.map((step, index) => (
                        <div key={index} style={styles.stepItem}>
                            <div style={styles.icon}>{step.icon}</div>
                            <div style={styles.stepDetails}>
                                <h3 style={styles.stepTitle}>{step.title}</h3>
                                <p style={styles.stepDescription}>{step.description}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

const styles = {
    container: {
        width: '100%',
        maxWidth: '700px',
        margin: '0 auto',
        padding: '20px',
        fontFamily: 'Arial, sans-serif',
    },
    stepsList: {
        display: 'flex',
        flexDirection: 'column',
        gap: '15px',
    },
    stepItem: {
        display: 'flex',
        alignItems: 'center',
        gap: '15px',
        padding: '15px',
        border: '1px solid #ddd',
        borderRadius: '8px',
        backgroundColor: '#f5f5f5',
    },
    icon: {
        fontSize: '32px',
        color: '#333',
    },
    stepDetails: {
        display: 'flex',
        flexDirection: 'column',
    },
    stepTitle: {
        margin: '0',
        fontSize: '18px',
        fontWeight: 'bold',
        color: '#333',
    },
    stepDescription: {
        margin: '5px 0 0',
        fontSize: '16px',
        color: '#555',
    },
};

export default HowItWorks;

import { addDoc, collection, deleteDoc, doc, getDocs, setDoc } from "firebase/firestore";
import { firestore } from "./firebase";
import { getUid } from "./auth";

export function addToFavorites(car){
    const favRef = doc(firestore, `users/${getUid()}/favorites/${car.Id}`)
    setDoc(favRef, {Id: car.Id, Photo: car.Photo, Price: car.Price, Mileage: car.Mileage, Manufacturer: car.Manufacturer, Model: car.Model, ModelGroup: car.ModelGroup})
}

export function removeFromFavorites(car){
    const favRef = doc(firestore, `users/${getUid()}/favorites/${car.Id}`)
    deleteDoc(favRef)
}

export function getFavorites(){
    const favRef = collection(firestore, `users/${getUid()}/favorites`)
    return new Promise((resolve, reject)=> {
        getDocs(favRef).then((qs)=> {
            const newDocs = qs.docs.map(doc => doc.data());
            resolve(newDocs)
        }).then(reject)
    })
}
import React from 'react';
import SimpleHeader from '../../components/SimpleHeader';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';

const AboutUs = () => {
  const { i18n } = useTranslation()
  return (
    <>
      <SimpleHeader title={t("about_us")} />
      <div style={styles.container}>
        {i18n.language == 'fr' ? <span>
          Bienvenue chez <b>DeCorée</b>, votre fournisseur de produits coréens authentiques en Mauritanie. Nous nous spécialisons dans la livraison d'une gamme diversifiée de produits de qualité supérieure directement depuis la Corée du Sud, y compris des voitures, des téléphones portables, des téléviseurs et des produits de soins de la peau. Chaque produit est soigneusement sélectionné pour répondre aux normes les plus élevées, garantissant à nos clients la meilleure expérience de qualité et d'innovation coréennes.
          <br /><br />
          Notre mission est de vous rapprocher des dernières avancées coréennes en matière de technologie, de beauté et de style de vie, pour enrichir votre quotidien avec des produits de qualité qui font la différence. Que vous recherchiez un article spécifique ou que vous ayez besoin d'une commande sur mesure, nous sommes là pour vous, avec des solutions d'expédition adaptées à vos besoins.
          <br /><br />
          Merci de nous avoir choisis pour faire partie de votre voyage vers la découverte et l'appréciation du meilleur de la Corée.
        </span> :
          <span>
            مرحبًا بكم في <b>DeCorée</b> (من كوريا)، مزودكم الموثوق للمنتجات الكورية الأصلية في موريتانيا. نتخصص في تقديم مجموعة متنوعة من المنتجات عالية الجودة مباشرة من كوريا الجنوبية، بما في ذلك السيارات والهواتف المحمولة والتلفزيونات ومنتجات العناية بالبشرة. يتم اختيار كل منتج بعناية ليتوافق مع أعلى المعايير، مما يضمن لعملائنا تجربة أفضل جودة وابتكار كوري.
            <br /><br />
            مهمتنا هي تقريبكم من أحدث الابتكارات الكورية في مجال التكنولوجيا والجمال وأسلوب الحياة، لإثراء حياتكم اليومية بمنتجات ذات جودة تُحدث فرقًا. سواء كنتم تبحثون عن منتج معين أو بحاجة لطلب مخصص، نحن هنا لخدمتكم مع حلول شحن تلبي احتياجاتكم.
            <br /><br />
            شكرًا لاختياركم DeCorée لتكون جزءًا من رحلتكم لاكتشاف أفضل ما تقدمه كوريا والاستمتاع به.
          </span>
        }
      </div>
    </>
  );
};

const styles = {
  container: {
    width: '100%',
    maxWidth: '600px',
    margin: '0 auto',
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
  },
  contactList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '15px',
  },
  contactItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    border: '1px solid #ddd',
    borderRadius: '15px',
    padding: '10px',
    paddingLeft: '20px',
    backgroundColor: '#f5f5f5',
    textTransform: 'none',
    textAlign: 'start',
    color: '#000'
  },
  icon: {
    fontSize: '24px',
    color: '#333',
  },
  contactDetails: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  link: {
    color: '#007bff',
    textDecoration: 'none',
  },
};

export default AboutUs;

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import './styles.css';
import SearchBar from './components/SearchBar';
import { getCount, getFilteredCarsQuery, getLatest, getRecommendedCars, getRIMPopularCars } from '../../services/car_data';
import SearchModal from '../../components/SearchModal';
import GridCars from './components/GridCars';
import { isLoggedIn } from '../../services/auth';
import { addToFavorites, removeFromFavorites } from '../../services/userInfo';
import useFavorites from '../../hooks/useFavorites';
import { useDispatch } from 'react-redux';
import { addFavorite, removeFavorite } from '../../reducers/favorites';
import Footer from '../../components/Footer';
import { t } from 'i18next';


const HomePage = forwardRef(({ onShowModal }, ref) => {
  const navigate = useNavigate()
  const [isLoading, setLoading] = useState(true)
  const [error, setError] = useState("")
  const [filteredCars, setFilteredCars] = useState([]);
  const favorites = useFavorites()
  const [show, setShow] = useState(false);
  const [recommendedCars, setRecommendedCars] = useState({cars: []});
  const [popularCars, setPopularCars] = useState({cars: []});
  const [orderedCars, setOrderedCars] = useState({cars: []});
  const [carCount, setCarCount] = useState(0)
  const dispatch = useDispatch()

  const handleShowFilters = () => {
    setShow(true);
    onShowModal(true)
  }
  const handleCloseFilters = () => {
    setShow(false);
    onShowModal(false)
  }


  function handleConfirmFilter(data) {
    setShow(false)
    navigate('/list', { state: { filters: data } });
    onShowModal(false)
  }

  function handleCardPress(id) {
    window.open(`/car/${id}`, '_blank');
  }

  function handleSeeMore({filters, sorting}){
    navigate('/list', { state: { filters: filters, sorting: sorting } });
  }

  function handleFavorite(car) {
    
    if(!isLoggedIn()) {
      alert(t("sign-in-to-like"))
      return
    }
    const id = car.Id

    const hasFavorite = favorites && favorites.some(item => item.Id === id);
    if (hasFavorite) {
      dispatch(removeFavorite(id))
      removeFromFavorites(car)
    } else {
      dispatch(addFavorite(car))
      addToFavorites(car)
    }
  }

  // (car.Price * 10000)/30)
  useEffect(() => {
    setError("")
    setLoading(true)
    getRecommendedCars().then((data) => {
      setRecommendedCars(data)
    }).finally(() => setLoading(false))
    getRIMPopularCars().then((data) => {
      setPopularCars(data)
    }).finally(() => setLoading(false))
    getLatest().then((data) => {
      setOrderedCars(data)
    }).finally(() => setLoading(false))
  }, [])

  useImperativeHandle(ref, () => (
    {
      showFilter: handleShowFilters
    }
  ))
  useEffect(() => {

      getCount(getFilteredCarsQuery({}, {})).then((data) => {
        setCarCount(data.count)
      })
  }, [])

  return (
    <>
      {/* <Header onSearch={handleShowFilters}/> */}
      {/* <CarCards/> */}
      <Container className="mt-0 d-flex flex-column" style={{ paddingLeft: 0, paddingRight: 0 }}>
        <span className='car-count-label'>{t('find_yours')}</span>
        <h3 className='car-count'>{` ${carCount ? carCount : "..."} `}{t('count_cars')}</h3>
        <SearchBar onSearch={handleShowFilters} />
        <div className='section-title mx-3'><span>{t('our_picks')}</span><button className='voir_plus' onClick={()=> handleSeeMore(recommendedCars)}>{t("see_more")}</button> </div >
        <GridCars cars={recommendedCars.cars} favorites={favorites} onFavorite={handleFavorite}/>
        <div className='section-title mx-3'><span>{orderedCars?.sorting ? `${t(orderedCars?.sorting?.label)}` : ""}</span><button className='voir_plus' onClick={()=> handleSeeMore(orderedCars)}>{t("see_more")}</button> </div >
        <GridCars cars={orderedCars.cars} favorites={favorites} onFavorite={handleFavorite}/>
        <div className='section-title mx-3'><span>{t("popular_in_rim")}</span><button className='voir_plus' onClick={()=> handleSeeMore(popularCars)}>{t("see_more")}</button> </div >
        <GridCars cars={popularCars.cars} favorites={favorites} onFavorite={handleFavorite}/>
      </Container>
      {show && <SearchModal show={show} handleClose={handleCloseFilters} onConfirm={handleConfirmFilter} />}
      <Footer/>
    </>
  );
})

export default HomePage;

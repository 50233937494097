import React, { useState } from 'react';
import { firestore, auth, googleProvider, RecaptchaVerifier, signInWithPhoneNumber, signInWithPopup, signInWithCredential, PhoneAuthProvider } from '../../services/firebase';
import './styles.css'
import { Card, Container } from 'react-bootstrap';
import { Button } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import { useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const Login = () => {
    const navigate = useNavigate()
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [verificationId, setVerificationId] = useState(null);
    const [error, setError] = useState('');

    // Function to handle Google sign in
    const signInWithGoogle = () => {
        signInWithPopup(auth, googleProvider)
            .then(result => {
                navigate('/')
            })
            .catch(error => {
                setError('Google sign-in failed. Please try again.');
            });
    };

    return (
        <Container className='d-flex flex-column align-items-center justify-content-center login-container'>
            <img src={require('../../images/login_bgd.png')} className='bgd' />
            <img src={require('../../images/logo.png')} className='login_logo' />
            <span className='slogan'>{t('slogan')}</span>
            <Card className='align-items-center px-4 py-4 mt-4' style={{borderRadius: 25}}>
                <h3 className='pb-4'>{t('sign_in')}</h3>
                <Button className='login-button px-5 mt-0'
                    sx={{ borderRadius: 100, backgroundColor: '#00966B', textTransform: 'none', fontSize: 16 }}
                    variant='contained'
                    startIcon={<LocalPhoneIcon className='mx-2'/>}
                    onClick={() => navigate("phone")}
                >
                    {t('by_phone')}
                </Button>
                <Button
                    className='login-button google-button px-5 mt-3'
                    sx={{ borderRadius: 100, borderColor: '#00966B', color: '#000', textTransform: 'none', fontSize: 16 }}
                    variant='outlined'
                    startIcon={<img src={require('../../images/google_icon.png')}
                        className='google-icon mx-2' />}
                    onClick={signInWithGoogle}
                >
                    {t('by_google')}
                </Button>
                {error && <p style={{ color: 'red' }} className='mt-4'>{error}</p>}
            </Card>

        </Container>
    );
};

export default Login;

import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Row, Col, Carousel, Button, Spinner, Modal } from 'react-bootstrap';
import { translateBrand, translateColors, translateFuel, translateModel, translateTransmission } from '../../services/translator';
import { formatMileage, getCarPhoto, isCarOnSale } from '../../utils/CarUtils';
import Header from './components/Header';
import './styles.css'
import { getCar, getCarDetails, svcRequestUpdateCar } from '../../services/car_data';
import { getMainOptions } from '../../data/data';
import DetailRow from './components/DetailRow';
import OptionsModal from './components/OptionsModal';
import useConversion from '../../hooks/useConversion';
import PricingDetails from './components/PricingDetails';
import { t } from 'i18next';
import { useTranslation } from 'react-i18next';
import CarUnavailable from '../../components/CarUnavailable';
import ImageViewer from 'react-simple-image-viewer';
import ImageViewerModal from './components/ImageViewerModal';


const CarDetailsPage = ({ props }) => {
  const { i18n } = useTranslation()
  const conversion = useConversion()
  const { id } = useParams();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [carData, setCarData] = useState()
  const [carDetails, setCarDetails] = useState()
  const [showAllOptions, setShowAllOptions] = useState(false)
  const [viewerIsOpen, setViewerOpen] = useState(false)
  const message = useRef(`${t('interested')}\n ${window.location.href}`)

  const handleSelect = (selectedIndex) => {
    setCurrentIndex(selectedIndex);
  };

  function sortByCode(arr) {
    return arr.sort((a, b) => {
      // Compare code values as strings to ensure proper sorting
      if (a.code < b.code) {
        return -1;
      }
      if (a.code > b.code) {
        return 1;
      }
      return 0;
    });
  }


  useEffect(() => {
    getCarDetails(id).then((carDetails) => {
      setCarDetails(carDetails)
      getCar(id).then((carData) => {
        setCarData(carData)
        if (carDetails.advertisement.status == "SOLD") {
          alert(t("car_bought"))
          if (carData.SalesStatus != "SOLD") {
            svcRequestUpdateCar(id, carDetails.advertisement.status).then(result => {
              setCarData({ ...carData, SalesStatus: carDetails.advertisement.status })
            }).catch((error) => console.error(error))
          }
        } else if (carDetails.advertisement.salesStatus == "CONTRACT") {
          alert(t("car_in_contract"))
          if (carData.SalesStatus != "CONTRACT") {
            svcRequestUpdateCar(id, carDetails.advertisement.salesStatus).then(result => {
              setCarData({ ...carData, SalesStatus: carDetails.advertisement.salesStatus })
            }).catch((error) => console.error(error))
          }
        } else if (carDetails.advertisement.status == "ADVERTISE") {
          if (!isCarOnSale(carData.SalesStatus)) {
            svcRequestUpdateCar(id, carDetails.advertisement.status).then(result => {
              setCarData({ ...carData, SalesStatus: carDetails.advertisement.status })
            }).catch((error) => console.error(error))
          }
        }

      })

    })

  }, [])

  function handleInquire() {
    window.open(`https://wa.me/+821029101962?text=${message.current}`, '_blank')
  }


  return (
    <>
      <Header car={carData} />
      {!carDetails ?
        <div className='d-flex justify-content-center align-items d-fill'>
          <Spinner className='text-success spinner-grow spinner-border-sm' />
        </div> : <>
          <div className="car-details-page ">
            {/* Carousel for multiple swipeable images */}
            <Carousel activeIndex={currentIndex} onSelect={handleSelect} interval={null} >
              {sortByCode(carDetails.photos).map((image, index) => (
                <Carousel.Item key={image?.code ?? index} >
                  <img
                    className="d-block w-100"
                    src={getCarPhoto(image.location ? image.location : image.path)}
                    alt={`Car image ${image?.code ?? index}`}
                    onClick={() => setViewerOpen(true)}
                  />
                </Carousel.Item>
              ))}
            </Carousel>

            {/* Display the current image index at the bottom right */}
            <div className="image-index">
              {currentIndex + 1} / {carDetails.photos.length}
            </div>
            {!isCarOnSale(carData?.SalesStatus) && <CarUnavailable status={carDetails.advertisement.salesStatus ?? carDetails.advertisement.status} />}
          </div>
          <Container className='mt-3' style={{ paddingBottom: 350 }}>
            <div className="d-flex justify-space-between">
              <h3 className='title'>{translateBrand(carDetails.category.manufacturerName)}{" "}
                {translateModel(carDetails.category.manufacturerName, carDetails.category.modelName)}</h3>
            </div>
            {carDetails && <div className="mt-3">
              <DetailRow label={t("year")} value={carDetails.category.formYear} />
              <DetailRow label={t("mileage")} value={formatMileage(carDetails.spec.mileage)} />
              <DetailRow label={t("fuel")} value={translateFuel(carDetails.category.gradeName, carDetails.spec.fuelName)} />
              <DetailRow label={t("transmission")} value={translateTransmission(carDetails.spec.transmissionName)} />
              <DetailRow label={t("displacement")} value={carDetails.spec.displacement + "cc"} />
              <DetailRow label={t("color")} value={translateColors(carDetails.spec.colorName)}>
                {/* {carData ? <div className="color_badge" style={{ backgroundColor: carData.ColorExpression?.split(";")[0] }} /> : null} */}
              </DetailRow>
            </div>}
            <Row className='mt-3'>
              <Col >
                <div className="d-flex mb-2">
                  <h3 className='subtitle' style={{ flex: 1 }}>{t("main_options")}</h3>
                  <button className="voir_plus" onClick={() => setShowAllOptions(!showAllOptions)}>{t("see_all_options")}</button>
                </div>

                <div className='cards mb-2'>
                  {carDetails ? <div className='main_options'>
                    {getMainOptions(i18n.language).map((option, i) => {

                      var hasOption = false
                      if (option.subOptions) {
                        for (var j = 0; j < option.subOptions.length; j++) {
                          const subOption = option.subOptions[j]
                          hasOption = carDetails.options.standard.includes(subOption.optionCd)

                          if (hasOption) {
                            option = { ...subOption, icon: option.icon }
                            break
                          }
                        }


                      } else {
                        hasOption = carDetails.options.standard.includes(option.optionCd)
                      }
                      const optionName = ["029", "075"].includes(option.optionCd) ? option.optionName.replace("(", "\n(") : option.optionName.split("(")[0];
                      return (
                        <div key={i}>
                          <img src={option.icon} style={{
                            opacity: hasOption ? 1 : 0.2
                          }} />
                          <span style={{ opacity: hasOption ? 1 : 0.2 }}>{optionName}</span>
                        </div>
                      )
                    })}
                  </div>
                    : <div className='loading'>{t("in_progress")}</div>}
                </div>
              </Col>
            </Row>

          </Container>
          <div className="footer">
            {carData && conversion ? <PricingDetails price={carData.Price} conversion={conversion} /> : null}
            <Button className="inquire-button w-100" variant='success' onClick={handleInquire}>
              <i className="fab fa-whatsapp" />
              <span className='mx-2' />
              <span>{t('contact_us')}</span>
            </Button>
          </div>
          <OptionsModal show={showAllOptions} options={carDetails?.options} handleClose={() => setShowAllOptions(!showAllOptions)} />
          <ImageViewerModal
              imageSrc={getCarPhoto(carDetails.photos[currentIndex].location ?? carDetails.photos[currentIndex].path)}
              isOpen={viewerIsOpen}
              onClose={()=> setViewerOpen(false)}
            />

        </>
      }
    </>
  );
};

export default CarDetailsPage;

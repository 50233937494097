import { useDispatch, useSelector } from "react-redux";
import { getFavorites } from "../services/userInfo";
import { setFavorites } from "../reducers/favorites";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../services/firebase";

function useFavorites() {
    const favorites = useSelector(state => state.favorites.value)
    const dispatch = useDispatch()

    onAuthStateChanged(auth, (user) => {
        if (user) {
            if (!favorites){
                getFavorites().then((_favorites) => {
                    dispatch(setFavorites(_favorites))
                }).catch((e) => { console.error("error getting favs", e) })
            }
        }
    })

    return favorites
}

export default useFavorites
import React, { useState } from 'react';
import './styles.css'; // Import custom CSS for styling
import SearchModal from '../../../../components/SearchModal';
import { replace, useLocation, useNavigate } from 'react-router-dom';
import { t } from 'i18next';

const SearchBar = ({ onSearch }) => {
  const navigate = useNavigate()
  const { state } = useLocation()
  const filters = state?.filters ?? {}
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  function handleConfirm(data) {
    onSearch && onSearch(data)
    setShow(false)
  }
  return (
    <>
      <div className="button-container mx-3 my-3">
        <button className="filtre-button" onClick={handleShow}>

          <i className="fas fa-sliders"></i>
          <span className='m-1'>{t('filters')}</span>
        </button>
      </div>
      {show && <SearchModal show={show} handleClose={handleClose} onConfirm={handleConfirm} filters={filters} />}
    </>
  );
};

export default SearchBar;
